import getSymbolFromCurrency from 'currency-symbol-map'
import DataTable from 'react-data-table-component';
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import React from "react";
import { customStylesTable } from './helpers';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import WarningIcon from '@mui/icons-material/Warning';
import { withStyles } from 'tss-react/mui';
import JSONPretty from "react-json-pretty";
import { formatSubstring } from './helpers';
import TransactionIdNotes from './components/TransactionIdNotes';
import { merchantTransactionStatuses } from '../../global/constant';

const TransactionTable = (props) => {
	const sortingValues = {
		"Merchant": "merchant.name",
		"Type": "type",
		"Acquirer": "acquirer",
		"Method": "method",
		"Traffic Type" : "trafficType",
		"Network": "network",
		"Transaction ID": "transactionId",
		"Status": "status.code",
		"Mechant User ID": "merchantUserId",
		"Merchant Transaction ID": "merchantTransactionId",
		"Last Updated": "status.updated",
		"Fiat Amount": "depositAmount",
		"Crypto Amount": "selected.amount",
		"Transaction Fee": "transactionFee",
		"Account Holder": "userName",
		"User Email": "merchantUserEmailAddress",
		"Card Issuer Country": "cardIssuerCountry",
		"User Country": "userCountry",
		"Masked PAN": "markedPan",
		"Card Scheme": "cardScheme",
		"Acquirer TransactionId" : "acquirerTransactionReference"
	}
	const merchantColumn = {
		name: <p data-e2e="name">{Object.keys(sortingValues)[0]}</p>,
		selector: row => row.merchant && row.merchant.name || "N/A",
		sortable: true,
		width: "150px",
		cell: row => 
		<div data-tag="allowRowEvents">{row.merchant && row.merchant.name || "N/A"}</div>,
	}
	
	const columns = [
		{
			name: <p data-e2e="type">{Object.keys(sortingValues)[1]}</p>,
			selector: row => row.type || "N/A",
			sortable: true,
			width: "165px",
			cell: row => <div data-tag="allowRowEvents" className="text-capitalize">{row.type || "N/A"}</div>
		},
		{
			name: <p data-e2e="type">{Object.keys(sortingValues)[2]}</p>,
			selector: row => row.acquirer || "N/A",
			sortable: true,
			cell: row => <div data-tag="allowRowEvents">{row.acquirer || "N/A"}</div>
		},
		{
			name: <p data-e2e="method">{Object.keys(sortingValues)[3]}</p>,
			selector: row => row.method || "N/A",
			sortable: true,
			cell: row => 
			<div data-tag="allowRowEvents">{row.method || "N/A"}</div>,
		},
		{
			name: <p data-e2e="trafficType">{Object.keys(sortingValues)[4]}</p>,
			selector: row => row.trafficType || "N/A",
			sortable: true,
			cell: row => 
			<div data-tag="allowRowEvents" data-e2e="traffic-type" data-e2e-traffic={row.trafficType || "N/A"}>{row.trafficType || "N/A"}</div>,
		},
		{
			name: <p data-e2e="network">{Object.keys(sortingValues)[5]}</p>,
			selector: row => row.network || "N/A",
			sortable: true,
			cell: row => 
			<div data-tag="allowRowEvents" data-e2e="tranx-id" data-e2e-tnx={row.transactionId || "N/A"}>{row.network || "N/A"}</div>,
		},
		{
			name: <p data-e2e="transactionId">{Object.keys(sortingValues)[6]}</p>,
			selector: row => row.transactionId || "N/A",
			sortable: true,
			width: "190px",
			cell: (row) => <TransactionIdNotes row={row} />
		},
		{
			name: <p data-e2e="status.code">{Object.keys(sortingValues)[7]}</p>,
			selector: row => row.status ? row.status.code : "N/A",
			sortable: true,
			width: "160px",
			cell: (row) => {
				const TopToolTipCompForValidation = row.type === "withdrawal" ? ToolTipForWithdrawTx : Tooltip;
				return (
					<div data-tag="allowRowEvents" className="theme-table-status-block">
						{row.status && row.status.code ?
							<Tooltip
                title= {
                  (row?.errorDetails && row?.errorDetails?.details)
                    ? row?.errorDetails?.details
                    : row?.status?.message
                }
                placement="top"
              >
								{getStatusComponent(row.status.code, row && row.selected &&  row.selected.actualAmountSent && row.selected.actualAmountSent) || "N/A"}
							</Tooltip>
							: "N/A"
						}
						{row.validation ?
							<TopToolTipCompForValidation title={<SubToolTipCompForValidation txValidationObj={row.validation} />}
							  placement="right">
							  {getTxValidationStatusIcon(row.validation)}
						  </TopToolTipCompForValidation> : null}
					</div>
				)
			}
		},
		{
			name: <p data-e2e="merchantUserId">{Object.keys(sortingValues)[8]}</p>,
			selector: row => row.merchantUserId || "N/A",
			sortable: true,
			cell: (row) => 
			<div data-tag="allowRowEvents">{formatSubstring(row.merchantUserId, 4, 12)}</div>
		},
		{
			name: <p data-e2e="merchantTransactionId">{Object.keys(sortingValues)[9]}</p>,
			selector: row => row.merchantTransactionId || "N/A",
			sortable: true,
			width: "145px",
			cell: (row) => 
			<div data-tag="allowRowEvents">
				{formatSubstring(row.merchantTransactionId, 4, 12)}
			</div>
		},
		{
			name: <p data-e2e="status.updated">{Object.keys(sortingValues)[10]}</p>,
			selector: row => row.status ? row.status.updated : "N/A",
			sortable: true,
			width: "145px",
			cell: (row) => 
			<div data-tag="allowRowEvents">
				{row.status && row.status.updated ? moment(row.status.updated).format("DD/MM/YYYY - HH:mm") : "N/A"}
			</div>
		},
		{
			name: <p data-e2e="depositAmount">{Object.keys(sortingValues)[11]}</p>,
			selector: row => row.depositAmount,
			sortable: true,
			width: "170px",
			cell: row => 
			<div data-tag="allowRowEvents">
				{row.depositFiatCurrency &&
				(row.depositAmount != null
				? getSymbolFromCurrency(row.depositFiatCurrency) + parseFloat(row.depositAmount).toFixed(6)
				: "0")}
			</div>
		},
		{
			name: <p data-e2e="amount">{Object.keys(sortingValues)[12]}</p>,
			selector: row => row.selected && row.selected.amount || "0",
			sortable: true,
			width: "165px",
			cell: row => 
			<div data-tag="allowRowEvents">
				{(row.selected &&
							row.selected.cryptoId +
								" - " +
								parseFloat(row.selected.amount).toFixed(6)) ||
							"0 "}
			</div>
		},
		{
			name: <p data-e2e="transactionFee">{Object.keys(sortingValues)[13]}</p>,
			selector: row => row.transactionFee || "0",
			sortable: true,
			cell: row => 
			<div data-tag="allowRowEvents">
              {(row.transactionFee == null || row.transactionFee === "" || isNaN(parseFloat(row.transactionFee))) ? "N/A" : parseFloat(row.transactionFee).toFixed(6)}
			</div>
		},
		{
			name: <p data-e2e="userDetails.Name">{Object.keys(sortingValues)[14]}</p>,
			selector: row =>  (row?.userDetails?.firstName + ' ' + row?.userDetails?.lastName) || 'N/A',
			sortable: true,
			width: "175px",
			cell: row => 
			<div data-tag="allowRowEvents">{(row?.userDetails?.firstName + ' ' + row?.userDetails?.lastName) || "N/A"}</div>,
		},
		{
			name: <p data-e2e="merchantUserEmailAddress">{Object.keys(sortingValues)[15]}</p>,
			selector: row =>  (row?.merchantUserEmailAddress) || 'N/A',
			sortable: true,
			width: "175px",
			cell: row => 
			<div data-tag="allowRowEvents">{(row?.merchantUserEmailAddress) || "N/A"}</div>,
		},
		{
			name: <p data-e2e="merchant.paymentProvider.country">{Object.keys(sortingValues)[16]}</p>,
			selector: row =>  (row?.merchant?.paymentProvider[0]?.country) || 'N/A',
			sortable: true,
			width: "150px",
			cell: row => 
			<div data-tag="allowRowEvents">{(row?.merchant?.paymentProvider[0]?.country) || "N/A"}</div>,
		},
		{
			name: <p data-e2e="userDetails.billingCountry">{Object.keys(sortingValues)[17]}</p>,
			selector: row =>  (row?.userDetails?.billingCountry) || 'N/A',
			sortable: true,
			width: "150px",
			cell: row => 
			<div data-tag="allowRowEvents">{(row?.userDetails?.billingCountry) || "N/A"}</div>,
		},
		{
			name: <p data-e2e="maskedPan">{Object.keys(sortingValues)[18]}</p>,
			selector: row =>  (row?.maskedPan) || 'N/A',
			sortable: true,
			width: "150px",
			cell: row => 
			<div data-tag="allowRowEvents">{(row?.maskedPan) || "N/A"}</div>,
		},
		{
			name: <p data-e2e="cardScheme">{Object.keys(sortingValues)[19]}</p>,
			selector: row =>  (row?.cardScheme) || 'N/A',
			sortable: true,
			width: "150px",
			cell: row => 
			<div data-tag="allowRowEvents">{(row?.cardScheme) || "N/A"}</div>,
		},
		{
			name: <p data-e2e="acquirerTransactionReference">{Object.keys(sortingValues)[20]}</p>,
			selector: row =>  (row?.acquirerTransactionReference) || 'N/A',
			sortable: true,
			width: "150px",
			cell: row => 
			<div data-tag="allowRowEvents">{(row?.acquirerTransactionReference) || "N/A"}</div>,
		},
	];
	
	const onChangePage = (page) => {
		props.setPage(page)
	}
	
	const customSort = (column, sortDirection) => {
		props.changeOrder(sortingValues[column.name.props.children])
	};
	
	const goToTransactionPage = (row) => {
    window.open(`/transaction/${row.transactionId}`, '_blank');
	};
	
	const getStatusComponent = (status, transactionActualAmount) => {
		switch (status) {
      case "DepositMonitor:DepositPendingManualApproval":
        return (
          <div
            className="theme-table-status witheld"
            data-e2e="REQUIRED APPROVAL"
            data-e2e-status="status"
            data-e2e-txId-balance={transactionActualAmount}
          >
            REQUIRED APPROVAL
          </div>
        );
      case "WithdrawalFlow:PendingApproval":
        return (
          <div
            className="theme-table-status witheld"
            data-e2e="WITHDRAW REQUEST"
            data-e2e-status="status"
            data-e2e-txId-balance={transactionActualAmount}
          >
            WITHDRAW REQUEST
          </div>
        );
      case "DepositMonitor:TimedOutMonitoringMemPool":
        return (
          <div
            className="theme-table-status witheld"
            data-e2e="timed-out"
            data-e2e-status="status"
            data-e2e-txId-balance={transactionActualAmount}
          >
            TIMED OUT
          </div>
        );
      case "DepositMonitor:DepositRejected":
      case "DepositMonitor:DepositFailed":
      case "DepositMonitor:RefundFailed":
      case "WalletManagementService:WithdrawalRejectionCompleted":
      case "WalletManagementService:WithdrawalError":
      case "DepositMonitor:DepositManualRejected":
      case "WalletManagementService:WalletTransferError":
      case "WithdrawalFlow:Rejected":
	  case merchantTransactionStatuses?.TRANSFER_REJECTED:
        return (
          <div
            className="theme-table-status inactive"
            data-e2e="rejected"
            data-e2e-status="status"
            data-e2e-txId-balance={transactionActualAmount}
          >
            REJECTED
          </div>
        );
      case "DepositFlow:Abandoned":
        return (
          <div
            className="theme-table-status inactive"
            data-e2e="rejected"
            data-e2e-status="status"
            data-e2e-txId-balance={transactionActualAmount}
          >
            Abandoned
          </div>
        );
      case "DepositMonitor:WalletStatusAvailable":
      case "DepositMonitor:WalletStatusLocked":
      case "DepositMonitor:UpdateWalletStatus":
      case "DepositMonitor:NotfiyMerchant":
      case "DepositMonitor:DepositManualApprove":
      case "DepositMonitor:KYTCheckFail":
      case "DepositMonitor:KYTCheckPass":
      case "DepositMonitor:KYTCheck":
      case "DepositMonitor:DepositConfirmed":
      case "DepositMonitor:GasTooLow":
      case "DepositMonitor:ExcessTransactionExecuted":
      case "DepositMonitor:TransactionExecuted":
      case "DepositMonitor:TransactionExecuting":
      case "DepositMonitor:DepositReceivedToMemPool":
      case "DepositMonitor:TransactionComplete":
        return (
          <div
            className="theme-table-status pending"
            data-e2e="PROCESSING"
            data-e2e-status="status"
            data-e2e-txId-balance={transactionActualAmount}
          >
            PROCESSING
          </div>
        );	
      case merchantTransactionStatuses?.DEPOSIT_CANCELLED:
        return (
          <div
            className="theme-table-status inactive"
            data-e2e="CANCELLED"
            data-e2e-status="status"
            data-e2e-txId-balance={transactionActualAmount}
          >
            CANCELLED
          </div>
        );
		case merchantTransactionStatuses?.DEPOSIT_ABANDONED:
		return (
			<div
			className="theme-table-status inactive"
			data-e2e="ABANDONED"
			data-e2e-status="status"
			data-e2e-txId-balance = {transactionActualAmount}

			>
			ABANDONED
			</div>
		);
      case "DepositMonitor:DepositManuallyCompleted":
      case "DepositMonitor:DepositCompleted":
      case "MerchantWallet:BalanceUpdated":
      case "WalletManagementService:WithdrawalCompleted":
      case "WalletManagementService:TransferCompleted":
	  case merchantTransactionStatuses?.TRANSFER_COMPLETED:
      case "DepositFlowViaWallet:DepositCompleted":
      case "MerchantWithdrawalFlow:Completed":
      case "DepositMonitor:DepositRefunded":
        return (
          <div
            className="theme-table-status active"
            data-e2e="success"
            data-e2e-status="status"
            data-e2e-txId-balance={transactionActualAmount}
          >
            SUCCESS
          </div>
        );
      case "CreatePayment":
      case "DepositFlow:Launched":
      case "DepositFlow:AwaitingDeposit":
      case "DepositFlow:DepositByCardFlowLaunched":
      case "DepositMonitor:MonitoringMemPool":
      case "CreateSecurePayment":
        return (
          <div
            className="theme-table-status inactive"
            data-e2e="pending"
            data-e2e-status="status"
            data-e2e-txId-balance={transactionActualAmount}
          >
            PENDING
          </div>
        );
      default:
        return (
          <div
            className="theme-table-status inactive"
            data-e2e="processing"
            data-e2e-status="status"
            data-e2e-txId-balance={transactionActualAmount}
          >
            PROCESSING
          </div>
        );
    }
	  };
	
	const SubToolTipCompForValidation = ({ txValidationObj }) => {
		return (
			<div className="wallettooltipcontainer deposit-tx-validation-tooltip">
				<div className="wallettooltipheading">Validation</div>
				<div className="wallettooltiptabletext">
					{
						Object.keys(txValidationObj).length > 0 ?
							Object.keys(txValidationObj).map(key => {
								const value = txValidationObj[key];
								return typeof value === 'boolean' ? <div className='wallettooltiptabletext' key={`deposit_tx_validation_tooltip_${key}`}>{`${key}: ${value}`}</div> 
									: typeof value === 'object' ? <div className='wallettooltiptabletext' key={`deposit_tx_validation_tooltip_${key}`}>{key}: <JSONPretty data={value} themeClassName="tooltip-json-pretty"/></div>: null
							})
							: null
					}
				</div>
			</div>
		)
	}
	
	const ToolTipForWithdrawTx = withStyles(Tooltip, {
		tooltip: {
		  maxWidth: "380px"
		}
	  });
	
	const getTxValidationStatusIcon = (txValidationObj) => {
		let isValid = true;
		for (const key in txValidationObj) {
			if (typeof txValidationObj[key] === 'boolean' && !txValidationObj[key]) {
				isValid = false;
				break;
			} else {
				if (typeof txValidationObj[key] === "object" && !txValidationObj[key]?.isValid) {
					isValid = false;
					break;
				}
			}
		}
		if (isValid) {
			return <CheckBoxIcon style={{ color: 'green', fontSize: '18px' }} />
		} else {
			return <WarningIcon style={{ color: 'red', fontSize: '18px' }} />
		}
	}
	
  return (
		<div>
			<br/>
			<br/>
			<DataTable
        onChangePage={onChangePage}
				paginationTotalRows={props.totalCount}
				paginationPerPage={10}
				paginationComponentOptions={{
					noRowsPerPage: true
				}}
				paginationServer
				pagination
				sortServer={true}
				columns={props.table_name === "Induvisual_transactions" ? columns : [merchantColumn, ...columns]}
				data={props.data}
				customStyles={customStylesTable}
				onSort={customSort}
				highlightOnHover={true}
				pointerOnHover={true}
				noDataComponent={<div style={{ padding: '24px', fontSize: "14px" }} data-e2e="no-data-to-display" 
				data-e2e-table="empty">There are no data to display</div>}
				onRowClicked={(row, e) => goToTransactionPage(row)}
			/>
		</div>
  );
};

export default TransactionTable;

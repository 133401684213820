import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Dialog from "@mui/material/Dialog";
import AddBlockedCard from "./AddBlockedCard";
import { isViewPermissionValid } from "../../../../../common/CommonUtils";
import Loading from "../../../../../common/Loading";
import DeletingAlert from "./DeletingAlert";
import { call } from "../../../../../../config/axios";
import {
  DELETE_BLOCKED_CARD_NEW,
  GET_BLOCKED_CARD_LIST,
} from "../../../../../../config/endpoints";
import localDb from "../../../../../../localDb";
import useDebounce from "../../../../global/useDebounce";
const searchicon = "/cryptonpay/crytoicons/searchicon1.png";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses?.head}`]: {
    backgroundColor: "#434373", //"#0000ff",
    color: theme?.palette?.common?.white,
  },
  [`&.${tableCellClasses?.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme?.palette?.action?.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const BlockCardList = (props) => {
  const baseURL = useSelector((state) => state.config.api_url);
  const [total, setTotal] = useState(0);
  const limit = 5;
  const [page, setPage] = useState(1);
  const [values, setValues] = useState(
    props?.acquireService?.routingService?.blockedList?.blockedCardList,
  );
  const imageBaseUrl = useSelector((state) => state.config.image_base_url);
  const newArr =
    props?.acquireService?.routingService?.blockedList?.blockedCardList;
  const [searchedText, setSearchedText] = useState("");
  const [loading, setLoading] = useState(false);
  const [openBlockedCardDailog, setOpenBlockedCardDailog] = useState(false);
  const [deleteCard, setDeleteCard] = useState(null);
  const handleCloseAddNew = () => {
    setOpenBlockedCardDailog(false);
  };
  const [openDeleteIcon, setOpenDeleteIcon] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const debouncedSearchText = useDebounce(searchedText);
  useEffect(() => {
    searchBlockList();
  }, [debouncedSearchText, page]);

  const openDeleteDialog = (card) => {
    setDeleteCard(card);
    setOpenDeleteIcon(true);
    setShowModel(true);
  };
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const handleDeleteFormBlockList = async () => {
    setLoading(true);
    const data = {
      email: localDb.getVal("email"),
      cardNumber: deleteCard,
      uniqueId: props?.uniqueId,
    };
    try {
      const res = await call(
        {
          ...DELETE_BLOCKED_CARD_NEW,
          url: baseURL + DELETE_BLOCKED_CARD_NEW.url,
        },
        data,
      );

      if (res?.status === 200) {
        setValues(res?.data?.routingService?.blockedList?.blockedCardList);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };
  const searchBlockList = async () => {
    setLoading(true);
    const data = {
      email: localDb.getVal("email"),
      searchCard: searchedText,
      uniqueId: props?.uniqueId,
      page,
      limit,
    };
    try {
      const res = await call(
        {
          ...GET_BLOCKED_CARD_LIST,
          url: baseURL + GET_BLOCKED_CARD_LIST.url,
        },
        data,
      );

      if (res?.status === 200) {
        setValues(res?.data?.blockedCardList);
        setTotal(res?.data?.totalCount);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  const totalPages = Math.ceil(total / limit);
  return (
    <div className="TabContainer">
      {loading && <Loading />}
      {props?.acquireService ? (
        <div>
          <div>
            <div className="pt-3">
              <div className="transactionFilter">
                <input
                  type="text"
                  className="transactionFilter__input"
                  value={searchedText}
                  name="searchText"
                  id="searchText"
                  data-e2e="search-textbox"
                  onChange={(e) => {
                    setSearchedText(e.target.value);
                    setPage(1);
                  }}
                  placeholder="Search Card Number"
                />
                <img
                  src={`${imageBaseUrl}${searchicon}`}
                  alt="searchicon"
                  className="transactionFilter__icon"
                  data-e2e="searchIcon"
                />
              </div>
              <TableContainer component={Paper} className="mt-3">
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">
                        Blocked Card List
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <div>
                          <button
                            type="button"
                            onClick={() =>
                              isViewPermissionValid
                                ? null
                                : setOpenBlockedCardDailog(true)
                            }
                            className={`icon ${isViewPermissionValid ? "disabled" : ""}`}
                          >
                            <AddIcon />
                          </button>
                        </div>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {values?.length > 0 &&
                    values?.map((card, i) => (
                      <TableBody>
                        <StyledTableRow key={i}>
                          <StyledTableCell align="left">
                            {card || "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <div
                              onClick={() => {
                                openDeleteDialog(card);
                              }}
                              className="delete-icon"
                            >
                              <DeleteIcon
                                className={`text-danger ${isViewPermissionValid ? "disabled" : ""}`}
                              />
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    ))}
                </Table>
              </TableContainer>
              {values?.length <= 0 && (
                <div className="flex w-full items-center justify-center py-4">
                  <p className="font-small text-xl">No Data available</p>
                </div>
              )}
              <div className="mt-4 flex items-center justify-center">
                <Pagination
                  count={totalPages}
                  variant="outlined"
                  shape="rounded"
                  onChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full text-center">No Data</div>
      )}
      {/* Add */}
      {openBlockedCardDailog && (
        <Dialog
          open={openBlockedCardDailog}
          onClose={() => {
            handleCloseAddNew();
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <AddBlockedCard
            handleCloseAddNew={() => handleCloseAddNew()}
            setOpenBlockedCardDailog={setOpenBlockedCardDailog}
            cardList={newArr}
            uniqueId={props?.uniqueId}
            reload={props?.reload}
            setReload={props?.setReload}
            setValues={setValues}
          />
        </Dialog>
      )}
      {/* delete  */}
      {openDeleteIcon && (
        <DeletingAlert
          imageBaseUrl={imageBaseUrl}
          msg=""
          onHide={() => {
            setShowModel(false);
            setOpenDeleteIcon(false);
          }}
          setOpenDeleteIcon={setOpenDeleteIcon}
          showModel={showModel}
          setShowModel={setShowModel}
          handleDeleteFormBlockList={handleDeleteFormBlockList}
        />
      )}
    </div>
  );
};

export default BlockCardList;

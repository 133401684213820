import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
const deleteIcon = "/cryptonpay/crytoicons/deleteicon.png";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import Loading from "../../../../../common/Loading";

function DeletingAlert(props) {
  const imageBaseUrl = useSelector((state) => state.config.image_base_url);
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    props?.setShowModel(false);
    props?.setOpenDeleteIcon(false);
    props?.handleDeleteFormBlockList();
  };

  return (
    <>
      <Modal
        show={props?.showModel}
        className="ClearAlertModal deleteAlertModal ClearAlertModals"
        onHide={() => {
          props?.onHide();
          props?.setShowModel(false);
        }}
      >
        <Modal.Body className="ClearAlertModalBody">
          <div className="ClearAlertModalIconWrap">
            <img
              src={`${imageBaseUrl}${deleteIcon}`}
              onClick={() => {
                props?.onHide();
              }}
              alt="resetIcon"
              className="ClearAlertModalIcon"
            />
          </div>
          <div className="ClearAlertModalTitle">Are you sure?</div>
          <div className="ClearAlertModalDescription">{`Are you sure you want to delete. This action cannot be undone.`}</div>
          <div
            className="ClearAlertModalClearBtn ClearAlertModalDeleteBtn"
            style={{ background: "#E02020" }}
            onClick={() => {
              handleDelete();
            }}
            data-e2e="clear"
          >
            Delete
          </div>
          <div
            className="ClearAlertModalGoBackBtn"
            onClick={() => {
              props?.onHide();
              props?.setOpenDeleteIcon(false);
              props?.setShowModel(false);
            }}
          >
            Go Back
          </div>
        </Modal.Body>
      </Modal>
      {loading && <Loading />}
    </>
  );
}
export default DeletingAlert;

import EditIcon from '@mui/icons-material/Edit';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import JSONPretty from "react-json-pretty";
import { useDispatch, useSelector } from "react-redux";
import MediaQuery from "react-responsive";
import { Link, useParams } from "react-router-dom";
import { Table } from "reactstrap";
import { call, logoutAfterError } from "../../../../config/axios";
import {
  GET_CRYPTO_AMOUNT_EXCHANGE_RATE,
  UPDATE_B2C_USER_WALLET_BALANCE,
  UPDATE_CENTRALISED_WALLET_BALANCE,
  UPDATE_MERCHNAT_WALLET_BALANCE,
  UPDATE_WALLET_BALANCE,
  VIEW_SINGLE_TRANSACTION,
} from "../../../../config/endpoints";
import localDb from "../../../../localDb";
import { setHeader } from "../../../Redux/actions/commonAction";
import Loading from "../../../common/Loading";
import EditWalletBalance from "../MobileViewCommonComponent/EditWalletBalance";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { isViewPermissionValid } from "../../../common/CommonUtils";
import constant, { merchantTransactionStatuses } from "../../global/constant";
const blockchainExternalLink = "/cryptonpay/blockchain_external_link.png";
const closeImage = "/cryptonpay/crytoicons/close.png";

function TransactionView(props) {
  const useStyles = styled(Tooltip)({
    tooltip: {
      fontSize: "1em",
    },
  });
  const classes = useStyles;

  const dispatch = useDispatch();
  const activatedMenu = useSelector((state) => state.common.header);
  const { id } = useParams();
  const BTC_MAINNET = useSelector((state) => state.config.btc_mainnet);
  const BTC_TESTNET = useSelector((state) => state.config.btc_testnet);
  const ETH_MAINNET = useSelector((state) => state.config.eth_mainnet);
  const ETH_SEPOLIA = useSelector((state) => state.config.eth_sepolia);
  const LTC_MAINNET = useSelector((state) => state.config.ltc_mainnet);
  const XRP_MAINNET = useSelector((state) => state.config.xrp_mainnet);
  const TRX_MAINNET = useSelector((state) => state.config.trx_mainnet);
  const TRX_TESTNET = useSelector((state) => state.config.trx_testnet);

  const [openData, setOpenData] = useState([]);
  const [data, setData] = useState([]);
  const [memoPoolReceiptData, setMemoPoolReceiptData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [balanceUpdateNote, changeBalanceUpdateNote] = useState("");
  const [editWalletBalanceData, setEditWalletBalanceData] = useState(null);
  const [newBalanceInCrypto, setNewBalanceInCrypto] = useState(null);
  const email = localDb.getVal("email");
  const [indexOfOpenTab, setIndexOpenTab] = useState(null);
  const [sortDataDetails, setSortDetails] = useState({
    walletDetails: "desc",
    transactionType: "desc",
    transactionStatus: "desc",
    fiatAmount: "desc",
    cryptoAmount: "desc",
    crypto: "desc",
    message: "desc",
  });

  const view_transaction_url = useSelector((state) => state.config.api_url);
  const image_base_url = useSelector((state) => state.config.image_base_url);

  const [accept, setAccept] = useState(false);
  const [reject, setReject] = useState(false);
  const [blockchain, setBlockchain] = useState(null);

  const handleAccpetCloseForm = () => {
    setAccept(false);
  };

  const handleRejectCloseForm = () => {
    setReject(false);
  };

  const getFiatCurrencySymbol = (fiatCurrency) => {
    switch (fiatCurrency) {
      case "USD":
        return "$";
      case "GBP":
        return "£";
      case "EUR":
        return "€";
      default:
        return "";
    }
  };

  const getCryptoLink = (network, cryptoId) => {
    let link = network + "-" + cryptoId;
    switch (link) {
      case "mainnet-BTC":
        return BTC_MAINNET;
      case "testnet-BTC":
        return BTC_TESTNET;
      case "mainnet-ETH":
      case "mainnet-USDT":
      case "mainnet-DAI":
      case "mainnet-USDC":
        return ETH_MAINNET;
      case "rinkeby-ETH":
      case "testnet-ETH":
      case "testnet-DAI":
      case "testnet-USDC":
      case "testnet-USDT":
        return ETH_SEPOLIA;
      case "mainnet-LTC":
        return LTC_MAINNET;
      case "mainnet-XRP":
        return XRP_MAINNET;
      case "mainnet-TRX":
      case "mainnet-TRC20-USDT":
        return TRX_MAINNET;
      case "testnet-TRX":
      case "testnet-TRC20-USDT":
        return TRX_TESTNET;
      default:
        return "";
    }
  };

  const getCryptoIcon = (symbol) => {
    switch (symbol) {
      case "BTC":
        return "/cryptonpay/crytoicons/bitcoin1.png";
      case "ETH":
        return "/cryptonpay/crytoicons/eth1.png";
      case "USDT":
        return "/cryptonpay/usdt.png";
      case "DAI":
        return "/cryptonpay/crytoicons/dai.png";
      case "BCC":
        return "/cryptonpay/crytoicons/btt_cash1.png";
      case "USDC":
        return "/cryptonpay/crytoicons/usd.png";
      case "EOS":
        return "/cryptonpay/crytoicons/eos.png";
      case "XRP":
        return "/cryptonpay/crytoicons/xrp.png";
      default:
        return "/cryptonpay/crytoicons/usd.png";
    }
  };
  const getCryptoBlockchain = (symbol) => {
    switch (symbol) {
      case "BTC":
        return "Bitcoin";
      case "ETH":
      case "USDT":
      case "DAI":
      case "USDC":
        return "Ethereum";
      case "LTC":
        return "Litecoin";
      case "XRP":
        return "Ripple";
      default:
        return "Tron";
    }
  };
  const viewSingleTransactionDetails = async () => {
    await call(
      {
        ...VIEW_SINGLE_TRANSACTION,
        url: view_transaction_url + VIEW_SINGLE_TRANSACTION.url,
      },
      { email: email, transactionId: id }
    )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data && res.data.transaction && res.data.transaction);
          if (
            res.data &&
            res.data.transaction &&
            res.data.transaction.status &&
            res.data.transaction.status
          ) {
            props.setStatus(res.data.transaction.status.code);
            props.setTransId(res.data.transaction.transactionId);
            props.setNotes(res.data.transaction.notes);
            props.setTranasactionType(res.data.transaction.type);
          }
          if (res.data.memoPoolReceipt && res.data.memoPoolReceipt.length > 1) {
            let reciptData = [];
            res.data.memoPoolReceipt.map((memo) => {
              reciptData.push(memo.receipt);
            });
            setMemoPoolReceiptData(reciptData);
          } else if (
            res.data.memoPoolReceipt &&
            res.data.memoPoolReceipt.length > 0
          ) {
            setMemoPoolReceiptData([res.data.memoPoolReceipt[0].receipt]);
          }
          if (
            res.data
            && res.data.transaction
            && res.data.transaction.selected
            && res.data.transaction.selected.cryptoId
          ) {
            const blockchain1 = getCryptoBlockchain(res.data.transaction.selected.cryptoId);
            setBlockchain(blockchain1);
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err);
      });
  };

  const getStatusComponent = (status) => {
    switch (status) {
      case "DepositMonitor:DepositPendingManualApproval":
      case "WithdrawalFlow:PendingApproval":
        return "Required Approval";
      case "DepositMonitor:TimedOutMonitoringMemPool":
        return "Timed Out";
      case "DepositMonitor:DepositRejected":
      case "DepositMonitor:DepositFailed":
      case "DepositMonitor:RefundFailed":
      case "WalletManagementService:WithdrawalRejectionCompleted":
        return "Rejected";
      case "DepositMonitor:WalletStatusAvailable":
      case "DepositMonitor:WalletStatusLocked":
      case "DepositMonitor:UpdateWalletStatus":
      case "DepositMonitor:NotfiyMerchant":
      case "DepositMonitor:DepositManualApprove":
      case "DepositMonitor:KYTCheckFail":
      case "DepositMonitor:KYTCheckPass":
      case "DepositMonitor:KYTCheck":
      case "DepositMonitor:DepositConfirmed":
      case "DepositMonitor:GasTooLow":
      case "DepositMonitor:ExcessTransactionExecuted":
      case "DepositMonitor:TransactionExecuted":
      case "DepositMonitor:TransactionExecuting":
      case "DepositMonitor:DepositReceivedToMemPool":
      case "DepositMonitor:TransactionComplete":
        return "Processing";
      case "DepositMonitor:DepositManuallyCompleted":
      case "DepositMonitor:DepositCompleted":
      case "MerchantWallet:BalanceUpdated":
      case "WalletManagementService:WithdrawalCompleted":
      case "WalletManagementService:TransferCompleted":
      case "DepositMonitor:DepositRefunded":
        return "Completed";
      case merchantTransactionStatuses?.DEPOSIT_CANCELLED:
        return "Cancelled";
      case merchantTransactionStatuses?.DEPOSIT_ABANDONED:
          return "Abandoned";
      case "CreatePayment":
      case "DepositFlow:Launched":
      case "DepositFlow:AwaitingDeposit":
      case "DepositMonitor:MonitoringMemPool":
      case "DepositMonitor:DepositPendingManualApproval":
        return "Pending";
      case "DepositMonitor:DepositRefunded":
        return "Refund Completed";
      case "N/A":
        return "N/A";
      default:
        return "Processing";
    }
  };
  const changeNewCryptoBalance = async (value) => {
    if (!isNaN(value)) {
      setError("");
      setNewBalanceInCrypto(value);
    }
  };

  const handleEditBalance = (walletData) => {
    setEditWalletBalanceData({ ...walletData });
    setNewBalanceInCrypto("");
  };

  const getExchangeRate = (fromCurrency, toCurrency, currencyAmount) =>
    new Promise(async (resolve, reject) => {
      if (fromCurrency == "USDC" || fromCurrency == "DAI")
        fromCurrency = "USDT";
      await call(
        {
          ...GET_CRYPTO_AMOUNT_EXCHANGE_RATE,
          url: view_transaction_url + GET_CRYPTO_AMOUNT_EXCHANGE_RATE.url,
        },
        {
          email: email,
          fromCurrency: fromCurrency,
          toCurrency: toCurrency,
          currencyAmount: currencyAmount,
        }
      )
        .then((res) => {
          if (res.data) {
            resolve(parseFloat(res.data).toFixed(8));
          }
        })
        .catch((err) => {
          setError("Something went wrong while fetching exchange values.");
          console.log(err);
          reject(null);
          logoutAfterError(err);
        });
    });

  const saveNewBalanceValues = async (cryptoCurrency, cryptoName) => {
    setLoading(true);
    if (newBalanceInCrypto != "") {
      const exchangeValue = await getExchangeRate(
        cryptoCurrency,
        "USD",
        newBalanceInCrypto
      );
      if (exchangeValue || exchangeValue === 0) {
        let callObj = {};
        let userWalletData = {};
        if (props.view === "Merchant Wallets") {
          callObj = {
            ...UPDATE_MERCHNAT_WALLET_BALANCE,
            url: view_transaction_url + UPDATE_MERCHNAT_WALLET_BALANCE.url,
          };
        }
        if (props.view === "Merchant User Wallets") {
          callObj = {
            ...UPDATE_WALLET_BALANCE,
            url: view_transaction_url + UPDATE_WALLET_BALANCE.url,
          };
        }
        if (props.view === "B2C User Wallets") {
          callObj = {
            ...UPDATE_B2C_USER_WALLET_BALANCE,
            url: view_transaction_url + UPDATE_B2C_USER_WALLET_BALANCE.url,
          };
          userWalletData = {
            userId: props.data.userId,
            coin: cryptoCurrency,
            cryptoName: getCryptoBlockchain(cryptoCurrency),
          };
        }
        if (props.view === "centralised wallets") {
          callObj = {
            ...UPDATE_CENTRALISED_WALLET_BALANCE,
            url: view_transaction_url + UPDATE_CENTRALISED_WALLET_BALANCE.url,
          };
        }
        await call(callObj, {
          email: email,
          newBalanceAmountInCrypto: newBalanceInCrypto,
          newBalanceAmountInUSD: exchangeValue,
          balanceUpdateNote,
          balanceId: editWalletBalanceData._id,
          walletId: id,
          ...userWalletData,
        })
          .then((res) => {
            setLoading(false);
            if (res.data) {
              props.setData(res?.data?.wallets);
              setEditWalletBalanceData(null);
              setShowEditWalletBalance(false);
              changeBalanceUpdateNote("");
            }
          })
          .catch((err) => {
            setError("Something went wrong while updating the wallet balance.");
            setLoading(false);
            console.log(err);
            logoutAfterError(err);
          });
      }
    } else {
      setLoading(false);
      setError("Something went wrong while fetching exchange rate.");
    }
  };

  useEffect(() => {
    let temp =
      id.substring(0, 10) + "...." + id.substring(id.length - 6, id.length);

    // if (props.handleFullId) {
    //   props.handleFullId(id);
    // }
    props.handleId(temp);

    if (props.view === "transactions") {
      viewSingleTransactionDetails();
    }

    [
      "Merchant Wallets",
      "Merchant User Wallets",
      "centralised wallets",
    ].includes(props.view)
      ? dispatch(setHeader("Wallets"))
      : dispatch(setHeader("Transactions"));

    if (
      [
        "Merchant Wallets",
        "Merchant User Wallets",
        "centralised wallets",
      ].includes(props.view)
    ) {
      dispatch(setHeader(props.view));
    } else if (props.view === "transactions") {
      dispatch(setHeader(activatedMenu));
    }
  }, []);

  const sortData = (itemToSort) => {
    let sortBy = null;
    let newSortBy = sortDataDetails[itemToSort] == "asc" ? "desc" : "asc";
    setSortDetails({
      ...sortDataDetails,
      [itemToSort]: newSortBy,
    });
    sortBy = newSortBy;
    const sortAlpha = (a, b) => {
      let nameA, nameB;
      if (itemToSort == "walletStatus") {
        nameA = a.code.toLowerCase();
        nameB = b.code.toLowerCase();
      } else if (itemToSort == "transactionType") {
        nameA = a.transactionDetails.type.toLowerCase();
        nameB = b.transactionDetails.type.toLowerCase();
      } else if (itemToSort == "transactionStatus") {
        nameA = a.transactionDetails.status.code.toLowerCase();
        nameB = b.transactionDetails.status.code.toLowerCase();
      } else if (itemToSort == "crypto") {
        return;
      } else if (itemToSort == "message") {
        nameA = a.transactionDetails.status.message.toLowerCase();
        nameB = b.transactionDetails.status.message.toLowerCase();
      }
      if (sortBy == "asc") {
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      } else {
        if (nameB < nameA) return -1;
        if (nameB > nameA) return 1;
        return 0;
      }
    };
    const sortDate = (a, b) => {
      if (sortBy == "asc") {
        if (a.updated > b.updated) return -1;
        if (b.updated > a.updated) return 1;
      } else {
        if (a.updated > b.updated) return 1;
        if (b.updated > a.updated) return -1;
      }
    };
    const sortNumbers = (a, b) => {
      let num1;
      let num2;
      if (itemToSort == "fiatAmount") {
        num1 = a.transactionDetails.depositAmount;
        num2 = b.transactionDetails.depositAmount;
      }
      if (itemToSort == "cryptoAmount") {
        num1 =
          a.transactionDetails && a.transactionDetails.selected
            ? a.transactionDetails.selected.amount
            : 0;
        num2 =
          b.transactionDetails && b.transactionDetails.selected
            ? b.transactionDetails.selected.amount
            : 0;
      }
      if (sortBy == "asc") {
        if (typeof num1 === "number" && typeof num2 === "number") {
          return num1 - num2;
        } else if (typeof a === "number") {
          return -1;
        } else if (typeof num2 === "number") {
          return 1;
        } else {
          return num1 > num2 ? 1 : -1;
        }
      } else {
        if (typeof num1 === "number" && typeof num2 === "number") {
          return num2 - num1;
        } else if (typeof a === "number") {
          return 1;
        } else if (typeof num2 === "number") {
          return -1;
        } else {
          return num1 > num2 ? -1 : 1;
        }
      }
    };
    let temp = { ...props.data };
    if (
      itemToSort == "walletStatus" ||
      itemToSort == "transactionStatus" ||
      itemToSort == "transactionType" ||
      itemToSort == "message" ||
      itemToSort == "crypto"
    ) {
      temp.newStatus.sort(sortAlpha);
    } else if (itemToSort == "fiatAmount" || itemToSort == "cryptoAmount") {
      temp.newStatus.sort(sortNumbers);
    } else if (itemToSort == "timeStamp") {
      temp.newStatus.sort(sortDate);
    }
    props.setData(temp);
  };

  function returnIcon(name) {
    if (name === "asc") {
      return (
        <i className="fa fa-chevron-up TransactionTable_heading_sortIcon sort_wallet_icon"></i>
      );
    } else {
      return (
        <i className="fa fa-chevron-down TransactionTable_heading_sortIcon sort_wallet_icon "></i>
      );
    }
  }
  const [detailBtn, setDetailBtn] = useState("Details");
  const [walletDetailBtn, setWalletDetailBtn] = useState("Wallets");
  const [showEditWalletBalance, setShowEditWalletBalance] = useState(false);

  const getTransactionStatus = (sts) => {
    switch (sts) {
      case "TIMED OUT":
      case "TRANSFER RQUESTED":
      case "Locked":
      case "Archived":
      case "Full":
      case "Transfer Requested":
      case "Locked - Transferring":
        return <div className="status status_timeout"></div>;
      case "Available":
        return <div className="status status_Success"></div>;
      case "Processing":
        return <div className="status status_processing"></div>;
      case "In Use":
      case "Pending":
        return <div className="status status_pending"></div>;
      default:
        <div className="status status_pending"></div>;
    }
  };

  const getTransactionId = (message, code) => {
    switch (code) {
      case "In Use":
      case "Updated":
        return message ? message : "N/A";
      case "Rejected Deposit":
        const matchResult = message.match(/tranferTrasactionID=([a-zA-Z0-9]+)/);
        return matchResult ? matchResult[1] : "N/A";
      default:
        return "N/A";
    }
  };

  return (
    <div className="wallet_container pt-0">
      {[
        "Merchant Wallets",
        "Merchant User Wallets",
        "centralised wallets",
        "B2C User Wallets",
      ].includes(props.view) ? (
        <div className="wallet_listing_page">
          <MediaQuery minWidth={768}>
            <div
              className="TransactionTableMain transaction_table_link table-full-width table_padding"
              style={{ flex: 1, marginTop: "40px" }}
            >
              {props.data &&
                props.data.balance &&
                props.data.balance.length > 0 && (
                  <Table responsive style={{ border: "none" }}>
                    <tbody
                      className="TransactionTable_BODY transaction_view_sub transaction_view_sub_Scroll make-table-flex scrollit up"
                      style={{ border: "none" }}
                    >
                      <tr className="tr-flex">
                        <th className="TransactionTable_heading transaction_table remove crypto-width">
                          Token
                        </th>
                        <th className="TransactionTable_heading transaction_table remove">
                          Balance
                        </th>
                        <th className="TransactionTable_heading transaction_table table-centered remove">
                          Last Updated
                        </th>
                      </tr>
                      {props.data &&
                        props.data.balance &&
                        props.data.balance.length > 0 &&
                        props.data.balance.map((item, index) => {
                          return (
                            <tr className="CP_TableRow tr-flex" key={index}>
                              <td className="transactionsTable_item transactionsTable_item_fonts balance-parent crypto-width d-flex align-items-center">
                                <img
                                  src={`${image_base_url}${getCryptoIcon(
                                    item.cryptoId
                                  )}`}
                                  alt={item.cryptoId}
                                  className="currencyList_Icon"
                                  data-e2e="currencyList_Icon"
                                  width="25px"
                                  height="25px"
                                />{" "}
                                <span>&nbsp;{item.cryptoName || "N/A"}</span>{" "}
                                <span style={{ color: "#afb4be" }}>
                                  ({item.cryptoId})
                                </span>
                              </td>
                              <td className="transactionsTable_item transactionsTable_item_fonts balance-parent ">
                                <div className="balanceAndEditWrap balance-child">
                                  <span>
                                    {item.balanceAmount
                                      ? item.balanceAmount
                                      : item.balanceAmount === 0
                                      ? 0
                                      : "-"}
                                  </span>
                                  <EditIcon
                                    data-e2e={`${
                                      item.cryptoName
                                        ? item.cryptoName
                                            .toLowerCase()
                                            .replaceAll(" ", "-")
                                        : ""
                                    }-eidt-icon`}
                                    data-e2e-amount={
                                      item.balanceAmount
                                        ? item.balanceAmount
                                        : item.balanceAmount === 0
                                        ? 0
                                        : "-"
                                    }
                                    fontSize="large"
                                    color="action"
                                    className={`cursor-pointer balanceEditButton ${isViewPermissionValid ? 'disabled' : ''}`}
                                    onClick={isViewPermissionValid ? null:() => {
                                      handleEditBalance(item);
                                    }}
                                  />
                                </div>
                              </td>
                              <td className="transactionsTable_item transactionsTable_item_fonts table-centered">
                                {item.updated
                                  ? moment(item.updated).format(
                                      "DD/MM/YYYY - HH:mm"
                                    )
                                  : "N/A"}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                    <tfoot>
                      {props.data &&
                        props.data.balance &&
                        props.data.balance.length > 0 && (
                          <tr
                            className="walletsTotalBalance"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <th
                              className="approx-left"
                              style={{ borderTopColor: "transparent" }}
                            >
                              Approx USD Balance
                            </th>
                            <th
                              className="approx-middle"
                              style={{ borderTopColor: "transparent" }}
                              data-e2e-val="amount"
                              data-e2e={
                                props.data
                                  ? props.data.balanceAmountUSDApprox
                                  : "N/A"
                              }
                            >
                              {props.data
                                ? props.data.balanceAmountUSDApprox
                                : "N/A"}
                            </th>
                            <th
                              className="approx-right"
                              style={{ borderTopColor: "transparent" }}
                            ></th>
                          </tr>
                        )}
                    </tfoot>
                  </Table>
                )}
            </div>
            {props.data &&
              props.data.newStatus &&
              props.data.newStatus.length > 0 && (
                <div
                  className="TransactionTableMain transaction_table_link table-full-width table_padding transaction-h"
                  style={{ flex: 1, marginTop: "10.5px" }}
                >
                  <div className="theme-table">
                    <Table responsive style={{maxHeight: '400px'}}>
                      <tbody>
                        <tr>
                          <th
                            className="theme-table-hover"
                            onClick={() => sortData("crypto")}
                          >
                            <div className="theme-table-text">
                              CRYPTO
                              {returnIcon(sortDataDetails.crypto)}
                            </div>
                          </th>
                          <th
                            className="theme-table-hover"
                            onClick={() => sortData("walletStatus")}
                          >
                            <div className="theme-table-text">
                              WALLET STATUS
                              {returnIcon(sortDataDetails.walletStatus)}
                            </div>
                          </th>
                          <th
                            className="theme-table-hover"
                            onClick={() => sortData("transactionType")}
                          >
                            <div className="theme-table-text">
                              TYPE
                              {returnIcon(sortDataDetails.transactionType)}
                            </div>
                          </th>
                          <th
                            className="theme-table-hover"
                            onClick={() => sortData("transactionType")}
                          >
                            <div className="theme-table-text">
                              TRANSACTION ID
                              {returnIcon(sortDataDetails.transactionType)}
                            </div>
                          </th>
                          <th
                            className="theme-table-hover"
                            onClick={() => sortData("timeStamp")}
                          >
                            <div className="theme-table-text">
                              TIME STAMP
                              {returnIcon(sortDataDetails.timeStamp)}
                            </div>
                          </th>
                          <th
                            className="theme-table-hover"
                            onClick={() => sortData("fiatAmount")}
                          >
                            <div className="theme-table-text">
                              FIAT AMOUNT
                              {returnIcon(sortDataDetails.fiatAmount)}
                            </div>
                          </th>
                          <th
                            className="theme-table-hover"
                            onClick={() => sortData("cryptoAmount")}
                          >
                            <div className="theme-table-text">
                              CRYPTO AMOUNT
                              {returnIcon(sortDataDetails.cryptoAmount)}
                            </div>
                          </th>
                          <th
                            className="theme-table-hover"
                            onClick={() => sortData("transactionStatus")}
                          >
                            <div className="theme-table-text">
                              STATUS
                              {returnIcon(sortDataDetails.transactionStatus)}
                            </div>
                          </th>
                          <th
                            className="theme-table-hover"
                            onClick={() => sortData("message")}
                          >
                            <div className="theme-table-text">
                              STATUS MESSAGE
                              {returnIcon(sortDataDetails.message)}
                            </div>
                          </th>
                        </tr>
                        {props.data &&
                          props.data.newStatus &&
                          props.data.newStatus.length > 0 &&
                          props.data.newStatus
                            .filter((i) => i.updated !== "N/A")
                            .map((ele, index) => {
                              let trId = getTransactionId(
                                ele.message && ele.message,
                                ele.code && ele.code
                              );
                              let crId =
                                ele.transactionDetails &&
                                ele.transactionDetails.selected &&
                                ele.transactionDetails.selected.cryptoId &&
                                ele.transactionDetails.selected.cryptoId;
                              return (
                                <React.Fragment key={index}>
                                  <tr className="theme-table-row theme-table-hover">
                                    <td className="theme-table-row">
                                      <div className="theme-table-row-td">
                                        <div className="theme-table-text theme-table-hover-content">
                                          <img
                                            src={`${image_base_url}${getCryptoIcon(
                                              crId
                                            )}`}
                                            alt={crId}
                                            className="currencyImg"
                                            data-e2e="currencyList_Icon"
                                            width="25px"
                                            height="25px"
                                          />{" "}
                                          <div>
                                            &nbsp;
                                            {getCryptoBlockchain(crId) || "N/A"}
                                          </div>{" "}
                                          <div style={{ color: "#afb4be" }}>
                                            ({crId})
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="theme-table-row">
                                      <div className="theme-table-row-td">
                                        <div className="theme-table-text">
                                          {ele.code}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="theme-table-row">
                                      <div className="theme-table-row-td">
                                        <div className="theme-table-text">
                                          {ele.transactionDetails
                                            ? ele.transactionDetails.type
                                            : "N/A"}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="theme-table-row">
                                      <div className="theme-table-row-td">
                                        <div className="theme-table-text">
                                          {!trId || trId === "N/A" ? (
                                            "N/A"
                                          ) : (
                                            <Link to={`/transaction/${trId}`}>
                                              <Tooltip
                                                classes={{
                                                  tooltip: classes.tooltip,
                                                }}
                                                title={trId}
                                                placement="top"
                                              >
                                                <div className="status-msg-text">
                                                  {trId.substring(0, 6) +
                                                    "....." +
                                                    trId.substring(
                                                      trId.length - 6,
                                                      trId.length
                                                    )}
                                                </div>
                                              </Tooltip>
                                            </Link>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="theme-table-row">
                                      <div className="theme-table-row-td">
                                        <div className="theme-table-text">
                                          {ele.updated.length > 4
                                            ? moment(ele.updated).format(
                                                "DD/MM/YYYY - HH:mm"
                                              )
                                            : "N/A"}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="theme-table-row">
                                      <div className="theme-table-row-td">
                                        <div className="theme-table-text">
                                          {ele.transactionDetails
                                            ? `${getFiatCurrencySymbol(
                                                ele.transactionDetails
                                                  .depositFiatCurrency
                                              )}${
                                                ele.transactionDetails
                                                  .depositAmount
                                              }`
                                            : "N/A"}
                                        </div>
                                      </div>
                                    </td>

                                    <td className="theme-table-row">
                                      <div className="theme-table-row-td">
                                        <div className="theme-table-text">
                                          {ele.transactionDetails &&
                                          ele.transactionDetails.selected
                                            ? `${ele.transactionDetails.selected.amount}`
                                            : "N/A"}
                                          <span className="grey-span">{` ${
                                            ele.transactionDetails &&
                                            ele.transactionDetails.selected &&
                                            ele.transactionDetails.selected
                                              .cryptoId != "N/A"
                                              ? "(" +
                                                ele.transactionDetails.selected
                                                  .cryptoId +
                                                ")"
                                              : ""
                                          }`}</span>
                                        </div>
                                      </div>
                                    </td>

                                    <td className="theme-table-row">
                                      <div className="theme-table-row-td">
                                        <div className="theme-table-text">
                                          {ele.transactionDetails ? (
                                            <span
                                              style={
                                                getStatusComponent(
                                                  ele.transactionDetails.status
                                                    .code
                                                ) == "Completed"
                                                  ? { color: "green" }
                                                  : { color: "red" }
                                              }
                                            >
                                              {getStatusComponent(
                                                ele.transactionDetails.status
                                                  .code
                                              ) === "Refund Completed"
                                                ? `${ele.transactionDetails.selected.actualAmountDeposited}(${ele.transactionDetails.selected.cryptoId}) `
                                                : ""}
                                              {getStatusComponent(
                                                ele.transactionDetails.status
                                                  .code
                                              )}
                                            </span>
                                          ) : (
                                            "N/A"
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="theme-table-row">
                                      <div className="theme-table-row-td">
                                        <div className="theme-table-text">
                                          <Tooltip
                                            classes={{
                                              tooltip: classes.tooltip,
                                            }}
                                            title={
                                              ele && ele.message && ele.message
                                            }
                                            placement="top"
                                          >
                                            <div className="status-msg-text">
                                              {(ele &&
                                                ele.message &&
                                                ele.message.substring(0, 8)) ||
                                                "-"}
                                            </div>
                                          </Tooltip>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              );
                            })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              )}
            {editWalletBalanceData && (
              <div className="merchant_dailog_screen">
                <Modal
                  show={true}
                  className="ClearAlertModal transferModal transferFundsModal"
                  onHide={() => {
                    setEditWalletBalanceData(null);
                  }}
                >
                  <Modal.Body className="ClearAlertModalBody transferModalBody">
                    <div className="transferModalTitleWrap">
                      <div className="transferModalTitle">Edit Balance</div>
                      <img
                        className="closeIcon"
                        alt="closeIcon"
                        src={`${image_base_url}${closeImage}`}
                        onClick={() => {
                          setEditWalletBalanceData(null);
                        }}
                      />
                    </div>
                    <div className="form-group myAccoutOptModal_form_group transferModalGroup">
                      <label
                        className="myAccoutOptModalLable"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Current Balance
                      </label>
                      <input
                        type="text"
                        data-e2e="current-balance"
                        disabled
                        value={editWalletBalanceData.balanceAmount}
                        className="form-control inputdropdown"
                        id="balanceAmount"
                        placeholder="Current Balance Amount"
                        name="balanceAmount"
                      />
                    </div>
                    <div className="form-group myAccoutOptModal_form_group transferModalGroup">
                      <label
                        className="myAccoutOptModalLable"
                        htmlFor="exampleFormControlSelect1"
                      >
                        New Balance
                      </label>
                      <input
                        type="text"
                        data-e2e="new-balance"
                        value={newBalanceInCrypto}
                        className="form-control inputdropdown"
                        id="newBalanceInCrypto"
                        placeholder="Enter New Balance"
                        name="newBalanceInCrypto"
                        onChange={(e) => {
                          changeNewCryptoBalance(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group myAccoutOptModal_form_group transferModalGroup">
                      <label
                        className="myAccoutOptModalLable"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Note
                      </label>
                      <textarea
                        rows="3"
                        cols="50"
                        value={balanceUpdateNote}
                        className="form-control inputdropdown"
                        id="balanceUpdateNote"
                        placeholder="Enter Note"
                        data-e2e="enter-note-text-box"
                        name="balanceUpdateNote"
                        style={{ minHeight: "100px" }}
                        onChange={(e) => {
                          changeBalanceUpdateNote(e.target.value);
                        }}
                      />
                    </div>
                    {error !== "" && (
                      <div className="limitErrorText">{error}</div>
                    )}
                    <div
                      className={`ClearAlertModalClearBtn transferFundsBtn ${
                        newBalanceInCrypto &&
                        balanceUpdateNote &&
                        newBalanceInCrypto != "" &&
                        balanceUpdateNote != "" &&
                        error === ""
                          ? ""
                          : "transferFundsBtnDisable"
                      }`}
                      onClick={() => {
                        if (
                          newBalanceInCrypto &&
                          balanceUpdateNote &&
                          newBalanceInCrypto != "" &&
                          balanceUpdateNote != "" &&
                          error === ""
                        ) {
                          saveNewBalanceValues(editWalletBalanceData.cryptoId);
                        }
                      }}
                      data-e2e="save-btn"
                    >
                      Save
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            )}
          </MediaQuery>

          <MediaQuery maxWidth={767}>
            <div className="TransactionBtn_container pt-5">
              <TransactionBtn
                detailBtn={walletDetailBtn}
                view={props.view}
                setDetailBtn={setWalletDetailBtn}
                btn={["Wallets", "Event log"]}
              />
            </div>

            <div className="wallets_detail_content">
              {walletDetailBtn === "Wallets" && (
                <div className="wallets_balanceWrap">
                  <div className="totalUsdBalance_row">
                    <div>{`Approx USD balance`}</div>
                    <div className="approx_balance">
                      {props.data ? props.data.balanceAmountUSDApprox : "N/A"}
                    </div>
                  </div>
                  {props.data &&
                    props.data.balance &&
                    props.data.balance.length > 0 &&
                    props.data.balance.map((item, index) => {
                      return (
                        <div className="balance_content_row" key={index}>
                          <div className="d-flex align-items-center w-40">
                            <img
                              src={`${image_base_url}${getCryptoIcon(
                                item.cryptoId
                              )}`}
                              alt={item.cryptoId}
                              className="currencyList_Icon"
                              data-e2e="currencyList_Icon"
                              width="25px"
                              height="25px"
                            />
                            <div className="pl-2 pr-1">
                              {item.cryptoName || "N/A"}
                            </div>
                            <div style={{ color: "#afb4be" }}>
                              ({item.cryptoId})
                            </div>
                          </div>
                          <div
                            className="text-primary"
                            onClick={() => {
                              handleEditBalance(item);
                              setShowEditWalletBalance(true);
                            }}
                          >
                            {item.balanceAmount
                              ? item.balanceAmount
                              : item.balanceAmount === 0
                              ? 0
                              : "-"}
                          </div>
                          <div>
                            {item.updated
                              ? moment(item.updated).format("DD/MM/YY")
                              : "N/A"}
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
              {walletDetailBtn === "Event log" && (
                <div className="wallets_balanceWrap">
                  {props.data &&
                    props.data.newStatus &&
                    props.data.newStatus.length > 0 &&
                    props.data.newStatus.map((ele, index) => {
                      return (
                        <WalletEventLogRow
                          data={props.data}
                          ele={ele}
                          getTransactionStatus={getTransactionStatus}
                          index={index}
                          image_base_url={image_base_url}
                          getFiatCurrencySymbol={getFiatCurrencySymbol}
                          getCryptoLink={getCryptoLink}
                        />
                      );
                    })}
                </div>
              )}
            </div>

            {showEditWalletBalance && (
              <EditWalletBalance
                open={showEditWalletBalance}
                onClose={() => setShowEditWalletBalance(false)}
                image_base_url={image_base_url}
                value={editWalletBalanceData.balanceAmount}
                balanceUpdateNote={balanceUpdateNote}
                newBalanceInCrypto={newBalanceInCrypto}
                changeNewCryptoBalance={changeNewCryptoBalance}
                changeBalanceUpdateNote={changeBalanceUpdateNote}
                saveNewBalanceValues={saveNewBalanceValues}
                editWalletBalanceData={editWalletBalanceData}
              />
            )}
          </MediaQuery>
        </div>
      ) : (
        <React.Fragment>
          <MediaQuery minWidth={768}>
            <div>
              {data && (
                <div>
                  <div
                    className="transaction_view"
                    style={{ marginBottom: "0px", marginTop: "0px" }}
                  >
                    <div
                      className="transaction_view_sub transaction_view_sub_Scroll"
                      style={{ overflowX: "hidden" }}
                    >
                      <div className="transaction_head">Details</div>
                      {
                        <div>
                          {" "}
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              Merchant
                            </div>
                            <div className="transaction_items">
                              {(data.merchant && data.merchant.name) || "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              Merchant User Email
                            </div>
                            <div className="transaction_items">
                              {(data.merchantUserEmailAddress &&
                                data.merchantUserEmailAddress) ||
                                "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">Type</div>
                            <div className="transaction_items text-capiltalize">
                              {(data && data.type && data.type) || "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              Acquirer
                            </div>
                            <div
                              className="transaction_items"
                              style={{ width: "100%" }}
                            >
                              {(data && data.acquirer && data.acquirer) ||
                                "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              Acquirer TransactionId
                            </div>
                            <div
                              className="transaction_items"
                            >
                              {(data?.acquirerTransactionReference) ||
                                "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">Method</div>
                            <div className="transaction_items text-capiltalize">
                              {(data && data.method && data.method) || "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              Network
                            </div>
                            <div className="transaction_items">
                              {(data && data.network && data.network) || "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                            Traffic Type
                            </div>
                            <div className="transaction_items">
                              {(data && data.trafficType && data.trafficType) || "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row MerchantDtls_cards_value">
                            <div className="transaction_subHeading">Status</div>
                            <div
                              className="transaction_items"
                              style={{ color: "#0091FF" }}
                            >
                              {(data &&
                                data.status &&
                                data.status.code &&
                                data.status.code) ||
                                "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row MerchantDtls_cards_value">
                            <div className="transaction_subHeading">
                              Status Message
                            </div>
                            <div
                              className="transaction_items"
                              style={{ color: "#0091FF", textAlign: "left" }}
                              data-e2e="message"
                              data-e2e-msg={(data &&
                                data.status &&
                                data.status.message &&
                                data.status.message) ||
                                "N/A"}
                            >
                              {(data &&
                                data.status &&
                                data.status.message &&
                                data.status.message) ||
                                "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              User ID
                            </div>
                            <div className="transaction_items">
                              {(data &&
                                data.merchantUserId &&
                                data.merchantUserId) ||
                                "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              Date Created
                            </div>
                            <div className="transaction_items">
                              {data && data.createdDate && data.createdDate
                                ? moment(data.createdDate).format("DD/MM/YYYY")
                                : "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              Last Updated
                            </div>
                            <div className="transaction_items">
                              {data && data.status && data.status.updated
                                ? moment(data.status.updated).format(
                                    "DD/MM/YYYY"
                                  )
                                : "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              From Address
                            </div>
                            <div className="transaction_items">
                              {data.type == "withdrawal" ? (
                                <a
                                  href={`/wallets/${
                                    data &&
                                    data.fromWallet &&
                                    data.fromWallet.address
                                  }`}
                                >
                                  {data &&
                                    data.fromWallet &&
                                    data.fromWallet.address}
                                </a>
                              ) : (
                                (data &&
                                  data.fromWallet &&
                                  data.fromWallet.address) ||
                                "N/A"
                              )}
                            </div>
                            {data &&
                              data.fromWallet &&
                              data.fromWallet.address && (
                                <a
                                  href={`${getCryptoLink(
                                    data && data.network && data.network,
                                    data &&
                                      data.selected &&
                                      data.selected.cryptoId &&
                                      data.selected.cryptoId
                                  )}/${
                                    data.fromWallet && data.fromWallet.address
                                  }`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    className="view-on-blockchain-btn-link"
                                    src={`${image_base_url}${blockchainExternalLink}`}
                                    alt={"external-link"}
                                    data-e2e={"external-link"}
                                    width="15px"
                                    height="15px"
                                  />
                                </a>
                              )}
                          </div>
                          <div className="transaction_items_row">
                            {data &&
                              data.type !== "deposit" &&
                              data.type !== "Admin-Deposit" && (
                                <div className="transaction_items_row">
                                  <div className="transaction_subHeading">
                                    To Address
                                  </div>
                                  <div className="transaction_items">
                                    {(data &&
                                      data.withdrawalWalletAddress &&
                                      data.withdrawalWalletAddress) ||
                                      (data.destinationWallet &&
                                        data &&
                                        data.destinationWallet.address &&
                                        data.destinationWallet.address) ||
                                      "N/A"}
                                  </div>
                                </div>
                              )}
                            {data && data.withdrawalWalletAddress && (
                              <a
                                href={`${getCryptoLink(
                                  data && data.network && data.network,
                                  data &&
                                    data.selected &&
                                    data.selected.cryptoId &&
                                    data.selected.cryptoId
                                )}/${data && data.withdrawalWalletAddress}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  className="view-on-blockchain-btn-link"
                                  src={`${image_base_url}${blockchainExternalLink}`}
                                  alt={"external-link"}
                                  data-e2e={"external-link"}
                                  width="15px"
                                  height="15px"
                                />
                              </a>
                            )}
                          </div>
                          {data && data.transactionHash && (
                            <div className="transaction_items_row">
                              <div className="transaction_items_row">
                                <div className="transaction_subHeading">
                                  Tx Hash
                                </div>
                                <div className="transaction_items">
                                  {data.transactionHash}
                                </div>
                              </div>
                              {blockchain && (
                                <a
                                  href={constant.BLOCKCHAIN_EXPLORER_URL[blockchain][data.network] + data.transactionHash}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    className="view-on-blockchain-btn-link"
                                    src={`${image_base_url}${blockchainExternalLink}`}
                                    alt={"external-link"}
                                    width="15px"
                                    height="15px"
                                  />
                                </a>
                              )}
                            </div>
                          )}
                          {data &&
                            data.depositWallet &&
                            (data.type === "deposit" ||
                              data.type === "MerchantWalletBalanceUpdate" ||
                              data.type === "Admin-Deposit") && (
                              <div className="transaction_items_row">
                                <div className="transaction_subHeading">
                                  Deposit Address
                                </div>
                                <Link
                                  to={`/wallets/${
                                    data &&
                                    data.depositWallet &&
                                    data.depositWallet.walletId
                                  }`}
                                >
                                  <div
                                    className="transaction_items"
                                    style={{
                                      color: "#0091FF",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    {data &&
                                    data.depositWallet &&
                                    data.depositWallet.address
                                      ? data.depositWallet.address.substring(
                                          0,
                                          10
                                        ) +
                                        "....." +
                                        data.depositWallet.address.substring(
                                          data.depositWallet.address.length - 6,
                                          data.depositWallet.address.length
                                        )
                                      : "N/A"}
                                  </div>
                                </Link>
                                {data &&
                                  data.depositWallet &&
                                  data.depositWallet.address &&
                                  data.depositWallet.address && (
                                    <a
                                      href={`${getCryptoLink(
                                        data && data.network && data.network,
                                        data &&
                                          data.selected &&
                                          data.selected.cryptoId &&
                                          data.selected.cryptoId
                                      )}/${
                                        data &&
                                        data.depositWallet &&
                                        data.depositWallet.address &&
                                        data.depositWallet.address
                                      }`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <img
                                        className="view-on-blockchain-btn-link"
                                        src={`${image_base_url}${blockchainExternalLink}`}
                                        alt={"external-link"}
                                        data-e2e={"external-link"}
                                        width="15px"
                                        height="15px"
                                      />
                                    </a>
                                  )}
                              </div>
                            )}
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              Crypto Value
                            </div>
                            <div className="transaction_items">
                              {(data &&
                                data.selected &&
                                data.selected.cryptoId +
                                  " - " +
                                  (data && data.type === "withdrawal"
                                    ? data &&
                                      data.fromWallet &&
                                      data.fromWallet.withdrawalAmountSent &&
                                      data.fromWallet.withdrawalAmountSent
                                    : data.selected.amount)) ||
                                "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              {" "}
                              Fiat Value
                            </div>
                            <div className="transaction_items">
                              {data &&
                                data.depositFiatCurrency &&
                                data.depositFiatCurrency &&
                                getFiatCurrencySymbol(
                                  data.depositFiatCurrency
                                ) + data &&
                                data.depositAmount}
                            </div>
                          </div>
                          {data.originalDepositAmount !==
                            data.depositAmount && (
                            <div className="transaction_items_row">
                              <div className="transaction_subHeading">
                                {`Original Deposit Amount`}
                              </div>
                              <div
                                className="transaction_items"
                                data-e2e={data.originalDepositAmount}
                              >
                                {data.originalDepositAmount}
                              </div>
                            </div>
                          )}
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              {" "}
                              Amount Sent
                            </div>
                            <div
                              className="transaction_items"
                              data-e2e-amount-send={
                                data &&
                                (data.type === "deposit" ||
                                  data.type === "Admin-Deposit")
                                  ? (data &&
                                      data.selected &&
                                      data.selected.actualAmountDeposited) ||
                                    "N/A"
                                  : (data &&
                                      data.selected &&
                                      data.selected.actualAmountSent &&
                                      data.selected.actualAmountSent) ||
                                    "N/A" +
                                      "-" +
                                      (data.selected &&
                                        data.selected.cryptoId) ||
                                    "N/A"
                              }
                            >
                              {data &&
                              (data.type === "deposit" ||
                                data.type === "Admin-Deposit")
                                ? (data &&
                                    data.selected &&
                                    data.selected.actualAmountDeposited) ||
                                  "N/A"
                                : (data &&
                                    data.selected &&
                                    data.selected.actualAmountSent &&
                                    data.selected.actualAmountSent) ||
                                  "N/A" +
                                    "-" +
                                    (data.selected && data.selected.cryptoId) ||
                                  "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              {" "}
                              Currency
                            </div>
                            <div className="transaction_items">
                              {data &&
                                data.depositFiatCurrency &&
                                data.depositFiatCurrency &&
                                getFiatCurrencySymbol(data.depositFiatCurrency)}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              {" "}
                              FX Rate
                            </div>
                            <div className="transaction_items">{"N/A"}</div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              Transaction Fee
                            </div>
                            <div className="transaction_items">
                              {(data &&
                                data.transactionFee &&
                                data.transactionFee) ||
                                "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              Transaction Fee On Failure
                            </div>
                            <div className="transaction_items">
                              { data &&
                                ( data?.transactionFeeOnFailure !== null && 
                                  data?.transactionFeeOnFailure !== undefined ? data?.transactionFeeOnFailure : "N/A"
                                )
                              }
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              Gas Fee
                            </div>
                            <div className="transaction_items">
                              {(data && data.gasFee && data.gasFee) || "N/A"}
                            </div>
                          </div>
                          {data && data.userDetails && (
                            <React.Fragment>
                              <div className="transaction_items_row">
                                <div className="transaction_subHeading">
                                  User Name
                                </div>
                                <div
                                  className="transaction_items"
                                  style={{ width: "100%" }}
                                >
                                  {data.userDetails.firstName !== null ||
                                  data.userDetails.firstName !== null
                                    ? `${
                                        data.userDetails.firstName !== null &&
                                        data.userDetails.firstName
                                      } ${
                                        data.userDetails.lastName !== null &&
                                        data.userDetails.lastName
                                      }`
                                    : "-"}
                                </div>
                              </div>
                              <div className="transaction_items_row">
                                <div className="transaction_subHeading">
                                  Billing Address
                                </div>
                                <div
                                  className="transaction_items"
                                  style={{ width: "100%" }}
                                >
                                  {`${
                                    (data.userDetails.billingAddress1 !==
                                      null &&
                                      data.userDetails.billingAddress1) ||
                                    "-"
                                  }`}
                                </div>
                              </div>
                              <div className="transaction_items_row">
                                <div className="transaction_subHeading">
                                  Billing City
                                </div>
                                <div
                                  className="transaction_items"
                                  style={{ width: "100%" }}
                                >
                                  {`${
                                    (data.userDetails.billingCity !== null &&
                                      data.userDetails.billingCity) ||
                                    "-"
                                  }`}
                                </div>
                              </div>
                              <div className="transaction_items_row">
                                <div className="transaction_subHeading">
                                  Billing Postcode
                                </div>
                                <div className="transaction_items">
                                  {`${
                                    (data.userDetails.billingPostcode !==
                                      null &&
                                      data.userDetails.billingPostcode) ||
                                    "-"
                                  }`}
                                </div>
                              </div>
                              <div className="transaction_items_row">
                                <div className="transaction_subHeading">
                                  Billing State
                                </div>
                                <div
                                  className="transaction_items"
                                  style={{ width: "100%" }}
                                >
                                  {`${
                                    (data.userDetails.billingState !== null &&
                                      data.userDetails.billingState) ||
                                    "-"
                                  }`}
                                </div>
                              </div>
                              <div className="transaction_items_row">
                                <div className="transaction_subHeading">
                                  Billing Country
                                </div>
                                <div
                                  className="transaction_items"
                                  style={{ width: "100%" }}
                                >
                                  {`${
                                    (data.userDetails.billingCountry !== null &&
                                      data.userDetails.billingCountry) ||
                                    "-"
                                  }`}
                                </div>
                              </div>
                            </React.Fragment>
                          )}
                          {data && data.transactionRefLink && (
                            <div className="transaction_items_row">
                              <div className="transaction_subHeading">
                                {`Transaction Link`}
                              </div>
                              <Link
                                to={`/b2c-user-transactions/${
                                  data && data.transactionRefLink
                                }`}
                              >
                                <div
                                  className="transaction_items"
                                  style={{
                                    color: "#0091FF",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {data &&
                                  data.transactionRefLink &&
                                  data.transactionRefLink
                                    ? data.transactionRefLink.substring(0, 10) +
                                      "....." +
                                      data.transactionRefLink.substring(
                                        data.transactionRefLink.length - 6,
                                        data.transactionRefLink.length
                                      )
                                    : "N/A"}
                                </div>
                              </Link>
                            </div>
                          )}
                        </div>
                      }
                    </div>
                    <div className="transaction_view_sub transaction_view_sub_Scroll">
                      <div className="transaction_head">Event Log </div>
                      {
                        <div>
                          {data &&
                            data.status &&
                            data.status.history &&
                            data.status.history.map((ele, index) => {
                              return (
                                <div
                                  className="transaction_subHeading"
                                  style={{
                                    display: "inline-block",
                                    marginBottom: "20px",
                                  }}
                                  key={index}
                                >
                                  {ele.updated
                                    ? moment(ele.updated).format(
                                        "DD/MM/YYYY - HH:mm:ss.SSS"
                                      )
                                    : "N/A"}{" "}
                                  -{" "}
                                  <div
                                    className="transaction_items eventLogItemWrap"
                                    style={{ textAlign: "left" }}
                                  >
                                    <span
                                      data-e2e={ele.code
                                        .toLowerCase()
                                        .replaceAll(" ", "-")}
                                    >
                                      {ele.code}
                                    </span>
                                    {ele.message && (
                                      <span>
                                        {indexOfOpenTab === index ? (
                                          <i
                                            className="fa fa-minus eventLogItemExpander"
                                            data-e2e={`${ele.code
                                              .toLowerCase()
                                              .replaceAll(
                                                ":",
                                                "-"
                                              )}-contraction`}
                                            onClick={() => {
                                              setIndexOpenTab(null);
                                            }}
                                          />
                                        ) : (
                                          <i
                                            data-e2e={`${ele.code
                                              .toLowerCase()
                                              .replaceAll(":", "-")}-expand`}
                                            className="fa fa-plus eventLogItemExpander"
                                            onClick={() => {
                                              setIndexOpenTab(index);
                                            }}
                                          />
                                        )}
                                      </span>
                                    )}
                                  </div>
                                  {ele.message && indexOfOpenTab === index && (
                                    <div className="eventLogItemMsg">
                                      {ele.message && (
                                        <div>
                                          {ele &&
                                          ele.message &&
                                          ele.message
                                            .toString()
                                            .includes("{") &&
                                          ele &&
                                          ele.message &&
                                          ele.message.toString().charAt(0) ===
                                            "{" ? (
                                            <JSONPretty
                                              id="json-pretty"
                                              data={ele && ele.message}
                                              data-e2e-code={ele.code}
                                              data-e2e={ele && ele.message}
                                              data-e2e-data="element"
                                            ></JSONPretty>
                                          ) : (
                                            <div
                                              style={{ whiteSpace: "pre-wrap" }}
                                              data-e2e={ele && ele.message}
                                              data-e2e-data="element"
                                            >
                                              {ele && ele.message}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      }
                    </div>
                  </div>
                  <div
                    className="transaction_view"
                    style={{ marginTop: "0px" }}
                  >
                    <div
                      className="transaction_view_sub transaction_view_sub_Scroll"
                      style={{ height: "617px" }}
                    >
                      <div className="transaction_head absolute_transaction_head">
                        Memopool Receipt{" "}
                      </div>
                      <div>
                        {/* {memoPoolReceiptData && memoPoolReceiptData.length > 1 ? (
                          <div>
                            <div className="transactionMemoPoolReceiptWrap">
                              <JSONPretty
                                id="json-pretty"
                                data={memoPoolReceiptData[0]}
                              ></JSONPretty>
                            </div>
                            <div className="transactionMemoPoolReceiptWrap">
                              <JSONPretty
                                id="json-pretty"
                                data={memoPoolReceiptData[1]}
                              ></JSONPretty>
                            </div>
                          </div>
                        ) :  */}

                        {memoPoolReceiptData &&
                        memoPoolReceiptData.length > 0 ? (
                          <div className="transactionMemoPoolReceiptWrap">
                            {memoPoolReceiptData.map(
                              (memoPoolReceiptData, i) => (
                                <JSONPretty
                                  key={i}
                                  id="json-pretty"
                                  data={memoPoolReceiptData}
                                ></JSONPretty>
                              )
                            )}
                          </div>
                        ) : (
                          <div className="NoDataFound">
                            MemoPoolReceipt data not found!
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </MediaQuery>

          <MediaQuery maxWidth={767}>
            <div className="TransactionBtn_container">
              <TransactionBtn
                detailBtn={detailBtn}
                setDetailBtn={setDetailBtn}
                btn={["Details", "Event log", "Mempool receipt"]}
              />
            </div>
            <div className="transaction_detail_content">
              {detailBtn === "Details" && (
                <div>
                  {" "}
                  <div className="transaction_items_row">
                    <div className="transaction_subHeading">Merchant</div>
                    <div className="transaction_items">
                      {(data.merchant && data.merchant.name) || "N/A"}
                    </div>
                  </div>
                  <div className="transaction_items_row">
                    <div className="transaction_subHeading">
                      Merchant User Email
                    </div>
                    <div className="transaction_items">
                      {(data.merchantUserEmailAddress &&
                        data.merchantUserEmailAddress) ||
                        "N/A"}
                    </div>
                  </div>
                  <div className="transaction_items_row">
                    <div className="transaction_subHeading">Type</div>
                    <div className="transaction_items text-capiltalize">
                      {(data && data.type && data.type) || "N/A"}
                    </div>
                  </div>
                  <div className="transaction_items_row">
                    <div className="transaction_subHeading">Acquirer</div>
                    <div className="transaction_items text-capiltalize">
                      {(data && data.acquirer && data.acquirer) || "N/A"}
                    </div>
                  </div>
                  <div className="transaction_items_row">
                    <div className="transaction_subHeading">Network</div>
                    <div className="transaction_items">
                      {(data && data.network && data.network) || "N/A"}
                    </div>
                  </div>
                  <div className="transaction_items_row MerchantDtls_cards_value">
                    <div className="transaction_subHeading">Status</div>
                    <div
                      className="transaction_items"
                      style={{ color: "#0091FF" }}
                    >
                      {(data &&
                        data.status &&
                        data.status.code &&
                        data.status.code) ||
                        "N/A"}
                    </div>
                  </div>
                  <div className="transaction_items_row MerchantDtls_cards_value">
                    <div className="transaction_subHeading">Status.Message</div>
                    <div
                      className="transaction_items"
                      style={{ color: "#0091FF" }}
                    >
                      {(data &&
                        data.status &&
                        data.status.message &&
                        data.status.message) ||
                        "N/A"}
                    </div>
                  </div>
                  <div className="transaction_items_row">
                    <div className="transaction_subHeading">User ID</div>
                    <div className="transaction_items">
                      {(data && data.merchantUserId && data.merchantUserId) ||
                        "N/A"}
                    </div>
                  </div>
                  <div className="transaction_items_row">
                    <div className="transaction_subHeading">Date Created</div>
                    <div className="transaction_items">
                      {data && data.createdDate && data.createdDate
                        ? moment(data.createdDate).format("DD/MM/YYYY")
                        : "N/A"}
                    </div>
                  </div>
                  <div className="transaction_items_row">
                    <div className="transaction_subHeading">Last Updated</div>
                    <div className="transaction_items">
                      {data && data.status && data.status.updated
                        ? moment(data.status.updated).format("DD/MM/YYYY")
                        : "N/A"}
                    </div>
                  </div>
                  <div className="transaction_items_row">
                    <div className="transaction_subHeading">From Address</div>
                    <div className="transaction_items">
                      {data.type == "withdrawal" ? (
                        <a
                          href={`/wallets/${
                            data && data.fromWallet && data.fromWallet.address
                          }`}
                        >
                          {data && data.fromWallet && data.fromWallet.address}
                        </a>
                      ) : (
                        (data && data.fromWallet && data.fromWallet.address) ||
                        "N/A"
                      )}
                    </div>
                    {data && data.fromWallet && data.fromWallet.address && (
                      <a
                        href={`${getCryptoLink(
                          data && data.network && data.network,
                          data &&
                            data.selected &&
                            data.selected.cryptoId &&
                            data.selected.cryptoId
                        )}/${data.fromWallet && data.fromWallet.address}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="view-on-blockchain-btn-link"
                          src={`${image_base_url}${blockchainExternalLink}`}
                          alt={"external-link"}
                          data-e2e={"external-link"}
                          width="15px"
                          height="15px"
                        />
                      </a>
                    )}
                  </div>
                  {/* <div className="transaction_items_row">
                    {data &&
                      data.type !== "deposit" &&
                      data.type !== "Admin-Deposit" && (
                        <div className="transaction_items_row">
                          <div className="transaction_subHeading">
                            To Address
                          </div>
                          <div className="transaction_items">
                            {(data && data.withdrawalWalletAddress &&
                              data.withdrawalWalletAddress) ||
                              (data.destinationWallet &&
                                data && data.destinationWallet.address &&
                                data.destinationWallet.address) ||
                              "N/A"}
                          </div>
                        </div>
                      )}
                    {data && data.withdrawalWalletAddress && (
                      <a
                        href={`${getCryptoLink(
                          data && data.network && data.network,
                          data &&
                          data.selected &&
                          data.selected.cryptoId &&
                          data.selected.cryptoId
                        )}/${data && data.withdrawalWalletAddress}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="view-on-blockchain-btn-link"
                          src={`${image_base_url}${blockchainExternalLink}`}
                          alt={"external-link"}
                          data-e2e={"external-link"}
                          width="15px"
                          height="15px"
                        />
                      </a>
                    )}
                  </div> */}
                  {data &&
                    data.depositWallet &&
                    (data.type === "deposit" ||
                      data.type === "MerchantWalletBalanceUpdate" ||
                      data.type === "Admin-Deposit") && (
                      <div className="transaction_items_row">
                        <div className="transaction_subHeading">
                          Deposit Address
                        </div>
                        <div className="transaction_items flexBoxJustifyEnd">
                          <Link
                            to={`/wallets/${
                              data &&
                              data.depositWallet &&
                              data.depositWallet.walletId
                            }`}
                          >
                            <div
                              style={{
                                color: "#0091FF",
                                textDecoration: "underline",
                              }}
                            >
                              {data &&
                              data.depositWallet &&
                              data.depositWallet.address
                                ? data.depositWallet.address.substring(0, 10) +
                                  "....." +
                                  data.depositWallet.address.substring(
                                    data.depositWallet.address.length - 6,
                                    data.depositWallet.address.length
                                  )
                                : "N/A"}
                            </div>
                          </Link>
                          {data &&
                            data.depositWallet &&
                            data.depositWallet.address &&
                            data.depositWallet.address && (
                              <a
                                href={`${getCryptoLink(
                                  data && data.network && data.network,
                                  data &&
                                    data.selected &&
                                    data.selected.cryptoId &&
                                    data.selected.cryptoId
                                )}/${
                                  data &&
                                  data.depositWallet &&
                                  data.depositWallet.address &&
                                  data.depositWallet.address
                                }`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  className="view-on-blockchain-btn-link"
                                  src={`${image_base_url}${blockchainExternalLink}`}
                                  alt={"external-link"}
                                  data-e2e={"external-link"}
                                  width="15px"
                                  height="15px"
                                />
                              </a>
                            )}
                        </div>
                      </div>
                    )}
                  <div className="transaction_items_row">
                    <div className="transaction_subHeading">Crypto Value</div>
                    <div className="transaction_items">
                      {(data &&
                        data.selected &&
                        data.selected.cryptoId +
                          " - " +
                          (data && data.type === "withdrawal"
                            ? data &&
                              data.fromWallet &&
                              data.fromWallet.withdrawalAmountSent &&
                              data.fromWallet.withdrawalAmountSent
                            : data.selected.amount)) ||
                        "N/A"}
                    </div>
                  </div>
                  <div className="transaction_items_row">
                    <div className="transaction_subHeading"> Fiat Value</div>
                    <div className="transaction_items">
                      {data &&
                        data.depositFiatCurrency &&
                        data.depositFiatCurrency &&
                        getFiatCurrencySymbol(data.depositFiatCurrency) +
                          data &&
                        data.depositAmount}
                    </div>
                  </div>
                  {data.originalDepositAmount !== data.depositAmount && (
                    <div className="transaction_items_row">
                      <div className="transaction_subHeading">
                        {`Original Deposit Amount`}
                      </div>
                      <div
                        className="transaction_items"
                        data-e2e={data.originalDepositAmount}
                      >
                        {data.originalDepositAmount}
                      </div>
                    </div>
                  )}
                  <div className="transaction_items_row">
                    <div className="transaction_subHeading"> Amount Sent</div>
                    <div
                      className="transaction_items"
                      data-e2e-amount-send={
                        data &&
                        (data.type === "deposit" ||
                          data.type === "Admin-Deposit")
                          ? (data &&
                              data.selected &&
                              data.selected.actualAmountDeposited) ||
                            "N/A"
                          : (data &&
                              data.selected &&
                              data.selected.actualAmountSent &&
                              data.selected.actualAmountSent) ||
                            "N/A" +
                              "-" +
                              (data.selected && data.selected.cryptoId) ||
                            "N/A"
                      }
                    >
                      {data &&
                      (data.type === "deposit" || data.type === "Admin-Deposit")
                        ? (data &&
                            data.selected &&
                            data.selected.actualAmountDeposited) ||
                          "N/A"
                        : (data &&
                            data.selected &&
                            data.selected.actualAmountSent &&
                            data.selected.actualAmountSent) ||
                          "N/A" +
                            "-" +
                            (data.selected && data.selected.cryptoId) ||
                          "N/A"}
                    </div>
                  </div>
                  <div className="transaction_items_row">
                    <div className="transaction_subHeading"> Currency</div>
                    <div className="transaction_items">
                      {data &&
                        data.depositFiatCurrency &&
                        data.depositFiatCurrency &&
                        getFiatCurrencySymbol(data.depositFiatCurrency)}
                    </div>
                  </div>
                  <div className="transaction_items_row">
                    <div className="transaction_subHeading"> FX Rate</div>
                    <div className="transaction_items">{"N/A"}</div>
                  </div>
                  <div className="transaction_items_row">
                    <div className="transaction_subHeading">
                      Transaction Fee
                    </div>
                    <div className="transaction_items">
                      {(data && data.transactionFee && data.transactionFee) ||
                        "N/A"}
                    </div>
                  </div>
                  <div className="transaction_items_row">
                    <div className="transaction_subHeading">Gas Fee</div>
                    <div className="transaction_items">
                      {(data && data.gasFee && data.gasFee) || "N/A"}
                    </div>
                  </div>
                  {data && data.transactionRefLink && (
                    <div className="transaction_items_row">
                      <div className="transaction_subHeading">
                        {`Transaction Link`}
                      </div>
                      <Link
                        to={`/b2c-user-transactions/${
                          data && data.transactionRefLink
                        }`}
                      >
                        <div
                          className="transaction_items"
                          style={{
                            color: "#0091FF",
                            textDecoration: "underline",
                          }}
                        >
                          {data &&
                          data.transactionRefLink &&
                          data.transactionRefLink
                            ? data.transactionRefLink.substring(0, 10) +
                              "....." +
                              data.transactionRefLink.substring(
                                data.transactionRefLink.length - 6,
                                data.transactionRefLink.length
                              )
                            : "N/A"}
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
              )}
              {detailBtn === "Event log" && (
                <div className="transaction_eventLog">
                  {data &&
                    data.status &&
                    data.status.history &&
                    data.status.history.map((ele, index) => {
                      return (
                        <div className="transaction_subHeading" key={index}>
                          {ele.updated
                            ? moment(ele.updated).format(
                                "DD/MM/YYYY - HH:mm:ss.SSS"
                              )
                            : "N/A"}{" "}
                          -{" "}
                          <div
                            className="transaction_items wrap_word eventLogItemWrap"
                            style={{ textAlign: "left" }}
                          >
                            <span
                              className="wrap_word overflow-hidden"
                              data-e2e={ele.code
                                .toLowerCase()
                                .replaceAll(" ", "-")}
                            >
                              {ele.code}
                            </span>
                            {ele.message && (
                              <span>
                                {indexOfOpenTab === index ? (
                                  <i
                                    className="pl-3 fa fa-chevron-up text-secondary eventLogItemExpander"
                                    data-e2e={`${ele.code
                                      .toLowerCase()
                                      .replaceAll(":", "-")}-contraction`}
                                    onClick={() => {
                                      setIndexOpenTab(null);
                                    }}
                                  />
                                ) : (
                                  <i
                                    data-e2e={`${ele.code
                                      .toLowerCase()
                                      .replaceAll(":", "-")}-expand`}
                                    className="pl-3 fa fa-chevron-down text-secondary eventLogItemExpander"
                                    onClick={() => {
                                      setIndexOpenTab(index);
                                    }}
                                  />
                                )}
                              </span>
                            )}
                          </div>
                          {ele.message && indexOfOpenTab === index && (
                            <div className="eventLogItemMsg">
                              {ele.message && (
                                <div>
                                  {ele &&
                                  ele.message &&
                                  ele.message.toString().includes("{") &&
                                  ele &&
                                  ele.message &&
                                  ele.message.toString().charAt(0) === "{" ? (
                                    <JSONPretty
                                      id="json-pretty"
                                      data={ele && ele.message}
                                      data-e2e-code={ele.code}
                                      data-e2e={ele && ele.message}
                                      data-e2e-data="element"
                                    ></JSONPretty>
                                  ) : (
                                    <div
                                      style={{
                                        whiteSpace: "pre-wrap",
                                        wordBreak: "break-word",
                                      }}
                                      data-e2e={ele && ele.message}
                                      data-e2e-data="element"
                                    >
                                      {ele && ele.message}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              )}
              {detailBtn === "Mempool receipt" && (
                <div className="transaction_eventLog">
                  {/* {memoPoolReceiptData && memoPoolReceiptData.length > 1 ? (
                    <div>
                      <div className="transactionMemoPoolReceiptWrap">
                        <JSONPretty
                          id="json-pretty"
                          data={memoPoolReceiptData[0]}
                        ></JSONPretty>
                      </div>
                      <div className="transactionMemoPoolReceiptWrap">
                        <JSONPretty
                          id="json-pretty"
                          data={memoPoolReceiptData[1]}
                        ></JSONPretty>
                      </div>
                    </div>
                  ) : */}
                  {memoPoolReceiptData && memoPoolReceiptData.length > 0 ? (
                    memoPoolReceiptData.map((memoPoolReceipt, i) => (
                      <div key={i} className="transactionMemoPoolReceiptWrap">
                        <JSONPretty
                          id="json-pretty"
                          data={memoPoolReceipt}
                        ></JSONPretty>
                      </div>
                    ))
                  ) : (
                    <div className="NoDataFound">
                      MemoPoolReceipt data not found!
                    </div>
                  )}
                </div>
              )}
            </div>
          </MediaQuery>
        </React.Fragment>
      )}
      {loading && <Loading />}
    </div>
  );
}

export default TransactionView;

export const TransactionBtn = (props) => {
  let btnArray = props.btn;
  if (props.view === "Merchant Wallets") {
    btnArray = ["Wallets"];
  }

  return (
    <div className="detail_btn">
      {btnArray.length > 0 &&
        btnArray.map((btn, i) => (
          <button
            onClick={() => props.setDetailBtn(btn)}
            key={i}
            type="button"
            className={`btn ${
              props.detailBtn == btn ? "btn-primary" : "btn btn-light border"
            } detail_btn_content`}
          >
            {btn}
          </button>
        ))}
    </div>
  );
};

const WalletEventLogRow = ({
  data,
  ele,
  index,
  getTransactionStatus,
  image_base_url,
  getCryptoLink,
  getFiatCurrencySymbol,
}) => {
  const [walletsEventlogDetails, setWalletsEventlogDetails] = useState(false);
  const WalletStatus = (status) => {
    switch (status) {
      case "Available":
        return (
          <div className="font-weight-normal text-capitalize text-success">
            {" "}
            {status}
          </div>
        );
      case "In Use":
        return (
          <div className="font-weight-normal text-capitalize text-danger">
            {" "}
            {status}
          </div>
        );
      case "Locked":
      case "Transfer Requested":
      case "Locked - Transferring":
        return (
          <div className="font-weight-normal text-capitalize text-warning">
            {" "}
            {status}
          </div>
        );
    }
  };
  return (
    <div key={index} className="single_mobile_row_wrap">
      <div className="single_mobile_row">
        <div className="row_status">{getTransactionStatus(ele.code)}</div>
        <div className="row_content">
          <div className="row_content_transInfo_left">
            <div className="d-flex align-items-center">
              <div className="mr-2">
                <img
                  src={`${image_base_url}${data.cryptoId.icon}`}
                  alt={data.cryptoId.icon}
                  className="currencyList_Icon"
                  data-e2e="currencyList_Icon"
                  width="30px"
                  height="30px"
                />
              </div>
              <div>
                <div className="merchant_name pb-2 text-capitalize">
                  {data.blockchain || "N/A"}
                </div>
                <div className="transaction_cryp">
                  {ele.transactionDetails && ele.transactionDetails.selected
                    ? `${ele.transactionDetails.selected.amount}`
                    : "N/A"}
                  <span className="grey-span">{` ${
                    ele.transactionDetails &&
                    ele.transactionDetails.selected &&
                    ele.transactionDetails.selected.cryptoId != "N/A"
                      ? "(" + ele.transactionDetails.selected.cryptoId + ")"
                      : ""
                  }`}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="row_content_transInfo_right">
            <div className="content">
              <div className="pb-2 text-capitalize">
                {" "}
                {ele.transactionDetails ? ele.transactionDetails.type : "N/A"}
              </div>
              {WalletStatus(ele.code)}
            </div>
            <div
              onClick={() => setWalletsEventlogDetails(!walletsEventlogDetails)}
              className="down_icon pl-3 pr-1"
            >
              <i
                className={`fa fa-chevron-${
                  !walletsEventlogDetails ? "down" : "up"
                }`}
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </div>
      </div>

      {walletsEventlogDetails && (
        <div className="transaction_details_wrap">
          <div className="content">
            <div className="content_heading">{`Fiat amount:: `}</div>
            <div className="content_val">
              {ele.transactionDetails
                ? `${getFiatCurrencySymbol(
                    ele.transactionDetails.depositFiatCurrency
                  )}${ele.transactionDetails.depositAmount}`
                : "N/A"}
            </div>
          </div>
          <div className="content">
            <div className="content_heading">{`Crypto amount: `}</div>
            <div className="content_val">
              {" "}
              {ele.transactionDetails && ele.transactionDetails.selected
                ? `${ele.transactionDetails.selected.amount}`
                : "N/A"}
              <span className="grey-span">{` ${
                ele.transactionDetails &&
                ele.transactionDetails.selected &&
                ele.transactionDetails.selected.cryptoId != "N/A"
                  ? "(" + ele.transactionDetails.selected.cryptoId + ")"
                  : ""
              }`}</span>
            </div>
          </div>
          <div className="content">
            <div className="content_heading">{`Transaction status: `}</div>
            <div className="content_val">{ele.code}</div>
          </div>
          <div className="content">
            <div className="content_heading">{`Time stamp: `}</div>
            <div className="content_val">
              {data.status && data.status.updated
                ? moment(data.status.updated).format("DD/MM/YYYY - HH:mm")
                : "N/A"}
            </div>
          </div>
          <div className="content">
            <div className="content_heading">{`Transaction message: `}</div>
            <div className="content_val">
              {" "}
              {ele.code == "In Use" && ele.message.length > 0 ? (
                <a
                  className="transaction-link text-primary"
                  href={`${getCryptoLink(
                    data && data.network && data.network,
                    data &&
                      data.selected &&
                      data.selected.cryptoId &&
                      data.selected.cryptoId
                  )}/transaction/${ele.message}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-e2e={ele.message}
                >
                  {ele.message.substring(0, 10) +
                    "..." +
                    ele.message.substring(
                      ele.message.length - 3,
                      ele.message.length
                    )}
                </a>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

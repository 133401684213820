import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCountryCodesFromDb } from "../../../../global/helpers";
import IOSSwitch from "../AcquirerConfig/IOSSwitch.js";
import Loading from "../../../../../common/Loading";
import { isViewPermissionValid } from "../../../../../common/CommonUtils";
import CPMultiCountryCode from "../AcquirerConfig/CPMultiCountryCode.js";
import ItemComponent from "../AcquirerConfig/ItemComponent.js";
import {
  TOGGLE__BLOCKED__COUNTRIES__ACTIVATION,
  GET_BLOCKED_COUNTRY_BY_SERVICE_LIST,
} from "../../../../../../config/endpoints.js";
import localDb from "../../../../../../localDb";
import { call } from "../../../../../../config/axios.js";
function RulesTab({ singleCardRoutingData }) {
  const baseURL = useSelector((state) => state.config.api_url);
  const [loading, setLoading] = useState(false);
  const [blockedCountryDropDown, setBlockedCountryDropDown] = useState(false);
  const [selectedCardCountryCode, setSelectedCardCountryCode] = useState([]);
  const [countryCodesData, setCountryCodesData] = useState([]);

  const onClickSwitch = (e) => {
    const payload = {
      isActive: !e?.target?.checked ? false : true,
      uniqueId: singleCardRoutingData?.uniqueId,
    };
    handletoggleActiveCountryCode(payload);
  };
  const handletoggleActiveCountryCode = async (payload) => {
    const data = {
      ...payload,
      email: localDb.getVal("email"),
    };
    setLoading(true);
    try {
      const res = await call(
        {
          ...TOGGLE__BLOCKED__COUNTRIES__ACTIVATION,
          url: baseURL + TOGGLE__BLOCKED__COUNTRIES__ACTIVATION.url,
        },
        data,
      );

      if (res?.status === 200) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };
  const fetchData = async () => {
    const countryCode = await getCountryCodesFromDb(baseURL);
    setCountryCodesData(countryCode);
  };
  const getActiveCountryCode = async (payload) => {
    const data = {
      uniqueId: singleCardRoutingData?.uniqueId,
      email: localDb.getVal("email"),
    };
    setLoading(true);
    try {
      const res = await call(
        {
          ...GET_BLOCKED_COUNTRY_BY_SERVICE_LIST,
          url: baseURL + GET_BLOCKED_COUNTRY_BY_SERVICE_LIST.url,
        },
        data,
      );

      if (res?.status === 200) {
        setLoading(false);
        setSelectedCardCountryCode(res?.data);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetchData();
    getActiveCountryCode();
  }, []);

  const toggleHandler = (currentArray, item, newArray, filterName) => {
    if (
      currentArray?.some((code) => code?.[filterName] === item?.[filterName])
    ) {
      let newList = currentArray?.filter(
        (code) => code?.[filterName] !== item?.[filterName],
      );
      newArray(newList);
    } else {
      let newList = [...currentArray];
      newList.push(item);
      newArray(newList);
    }
  };
  const toggleCardCountry = (item = "") => {
    return toggleHandler(
      selectedCardCountryCode,
      item,
      setSelectedCardCountryCode,
      "code",
    );
  };

  return (
    <div className="TabContainer d-flex flex-column">
      {loading && <Loading />}
      <div className="w-full">
        <>
          <div className="rulesTab">
            <div className="w-80">
              <div className="text-[16px] font-medium">
                Blocked countries by Service
              </div>
              <div className="text-[12px] text-gray-700">
                Select the countries that should be blocked by merchant
              </div>
            </div>
            <div className={`mx-6 ${isViewPermissionValid ? "disabled" : ""}`}>
              <IOSSwitch
                defaultChecked={
                  singleCardRoutingData?.routingService?.rules
                    ?.blockedCountriesByServiceActive
                }
                onClickSwitch
                onClick={(e) =>
                  isViewPermissionValid ? null : onClickSwitch(e)
                }
              />
            </div>
            <div className="ml-8 w-2/5">
              <div className="mb-2 text-[14px] ">Location</div>
              <div
                className={`FilterModalInputDropdown ${isViewPermissionValid ? "disabled" : ""}`}
                onClick={() =>
                  isViewPermissionValid ? null : setBlockedCountryDropDown(true)
                }
              >
                <div
                  className="FilterModalInputText"
                  data-e2e="crypto-currency-expand"
                >
                  {countryCodesData?.length ===
                    selectedCardCountryCode?.length ||
                  selectedCardCountryCode?.length === 0
                    ? "Select options"
                    : `${selectedCardCountryCode?.length} Selected`}
                </div>
                <i className="fa fa-chevron-down FilterModalInputDropIcon" />
              </div>

              {blockedCountryDropDown ? (
                <CPMultiCountryCode
                  dataE2eLabel="crypto-urrency-"
                  items={countryCodesData}
                  serviceUniqueId={singleCardRoutingData?.uniqueId}
                  field="cardCountryCodeBasedOnService"
                  selectedItems={selectedCardCountryCode}
                  setShowDropDown={setBlockedCountryDropDown}
                  setSelectedItems={(items) => {
                    setSelectedCardCountryCode(items || []);
                  }}
                  ItemComponent={({ item: code }) => {
                    return (
                      <ItemComponent
                        toggleAllowed={toggleCardCountry}
                        item={code}
                        selectedArray={selectedCardCountryCode}
                      />
                    );
                  }}
                />
              ) : null}
            </div>
          </div>
        </>
      </div>
    </div>
  );
}

export default RulesTab;

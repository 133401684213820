import React, { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import DateRangePicker2 from "react-bootstrap-daterangepicker";
import { CPMultiSelect } from "../../../common/CPMultiSelect";
import "bootstrap-daterangepicker/daterangepicker.css";

const dateImage = "/cryptonpay/crytoicons/date.png";
const selectedCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_filled.png";
const emptyCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_empty.png";

const DropdownItem = ({
  item,
  selectedItems,
  toggleSelection,
  image_base_url,
  selectedCheckBoxIcon,
  emptyCheckBoxIcon,
}) => {
  const isSelected = selectedItems.some((selected) =>
    typeof item === "object" ? selected?._id === item?._id : selected === item,
  );

  return (
    <div className="currencyListItemWrap" onClick={() => toggleSelection(item)}>
      <div className="currencyListIconNameWrap">
        <div
          className="currencyList_Name"
          id="available-test"
          data-e2e={
            typeof item === "object"
              ? item?.name?.toLowerCase().replaceAll(" ", "-")
              : item.toLowerCase().replaceAll(" ", "-")
          }
        >
          {typeof item === "object" ? item?.name : item}
        </div>
      </div>
      <img
        src={`${image_base_url}${
          isSelected ? selectedCheckBoxIcon : emptyCheckBoxIcon
        }`}
        alt="checkbox"
        className="currencyListCheckBox"
        data-e2e={`${
          typeof item === "object"
            ? item?.name.toLowerCase().replaceAll(" ", "-")
            : item.toLowerCase().replaceAll(" ", "-")
        }-checkbox`}
      />
    </div>
  );
};

export default function CardDashboardFilter(props) {
  const { setFromDate, setToDate, fromDate, toDate } = props;
  const image_base_url = useSelector((state) => state.config.image_base_url);

  const handleCallback = (start, end) => {
    props.setFromDate(moment(start).format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
    props.setToDate(moment(end).format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
    props.setDateSelected(true);
  };

  const formatDate = (date) => moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ");

  const onChangeDate = (dateTimeRange) => {
    setFromDate(dateTimeRange ? formatDate(dateTimeRange[0]) : null);
    setToDate(dateTimeRange ? formatDate(dateTimeRange[1]) : null);
  };

  const [showDropdown, setShowDropdown] = useState({
    routingServices: false,
    merchant: false,
    acquirer: false,
    cardSchema: false,
    country: false,
    status: false,
    error: false,
    statusGroup: false,
  });

  const toggleDropdown = (key) => {
    setShowDropdown((prev) => {
      const newDropdownState = {};
      Object.keys(prev).forEach((k) => {
        newDropdownState[k] = k === key ? !prev[key] : false;
      });
      return newDropdownState;
    });
  };

  const closeReset = () => resetOpenPopup(false);

  const toggleSelection = (selectedItems, setSelectedItems, symbol) => {
    const newList = selectedItems.includes(symbol)
      ? selectedItems.filter((item) => item !== symbol)
      : [...selectedItems, symbol];
    setSelectedItems(newList);
  };

  const sortDropDownData = (data, field) => {
    if (!data || data.length === 0) {
      return []; // Return an empty array if data is undefined or empty
    }

    if (field) {
      const filteredData = data.filter(
        (item) => item[field] !== undefined && item[field] !== null,
      );
      return filteredData.sort((a, b) =>
        a[field].localeCompare(b[field], { sensitivity: "accent" }),
      );
    } else {
      return data.sort((a, b) => a.localeCompare(b, { sensitivity: "accent" }));
    }
  };

  const filterFields = [
    {
      label: "Routing Service",
      key: "routingServices",
      items: props.routingServices,
      selectedItems: props.selectedRoutingServices,
      setSelectedItems: props.setSelectedRoutingServices,
    },
    {
      label: "Merchant",
      key: "merchant",
      items: props.merchants,
      selectedItems: props.selectedMerchants,
      setSelectedItems: props.setSelectedMerchants,
    },
    {
      label: "Acquirer",
      key: "acquirer",
      items: sortDropDownData(props.acquirers),
      selectedItems: props.selectedAcquirers,
      setSelectedItems: props.setSelectedAcquirers,
    },
    {
      label: "Card Schema",
      key: "cardSchema",
      items: sortDropDownData(props.cardSchemas),
      selectedItems: props.selectedCardSchemas,
      setSelectedItems: props.setSelectedCardSchemas,
    },
    {
      label: "Country",
      key: "country",
      items: sortDropDownData(props.countries),
      selectedItems: props.selectedCountries,
      setSelectedItems: props.setSelectedCountries,
    },
    {
      label: "Status",
      key: "status",
      items: sortDropDownData(props.statuses),
      selectedItems: props.selectedStatuses,
      setSelectedItems: props.setSelectedStatuses,
    },
    {
      label: "Status Group",
      key: "statusGroup",
      items: sortDropDownData(props.statusGroups),
      selectedItems: props.selectedStatusGroups,
      setSelectedItems: props.setSelectedStatusGroups,
    },
    {
      label: "Error",
      key: "error",
      items: sortDropDownData(props.errors),
      selectedItems: props.selectedErrors,
      setSelectedItems: props.setSelectedErrors,
    },
  ];

  return (
    <div className="card_filter">
      <div className="filter_row">
        {filterFields.slice(0, 4).map(({ label, key, items, selectedItems, setSelectedItems }) => (
          <div className="card_filter_list" key={key}>
            <div className="FilterModalInputGroup">
              <div className="FilterModalInputLabel">{label}</div>
              <div className="FilterModalInputDropdown" onClick={() => toggleDropdown(key)}>
                <div className="FilterModalInputText">{selectedItems.length ? `${selectedItems.length} Selected` : 'All'}</div>
                <i className="fa fa-chevron-down" />
              </div>
            </div>
          </div>
        ))}
        <button onClick={props.handleSave} className="filter_button">
          Save Filters
        </button>
      </div>
      <div className="filter_row">
        {filterFields.slice(4).map(({ label, key, items, selectedItems, setSelectedItems }) => (
          <div className="card_filter_list" key={key}>
            <div className="FilterModalInputGroup">
              <div className="FilterModalInputLabel">{label}</div>
              <div className="FilterModalInputDropdown" onClick={() => toggleDropdown(key)}>
                <div className="FilterModalInputText">{selectedItems.length ? `${selectedItems.length} Selected` : 'All'}</div>
                <i className="fa fa-chevron-down" />
              </div>
            </div>
          </div>
        ))}
        <button onClick={props.handleReset} className="filter_button">
          Reset Filters
        </button>
      </div>
    </div>
  );
  
}


